// import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'
import { useAuth } from './context/AuthContext'
import './styles/app.css'

// Components
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Spinner from './components/spinner/Spinner'

import ProfileGeneralTab from './modals/viewProfile/profileGeneralTab/ProfileGeneralTab'
import ProfilePostsTab from './modals/viewProfile/profilePostsTab/ProfilePostsTab'
import ProfileCommentsTab from './modals/viewProfile/profileCommentsTab/ProfileCommentsTab'

// Pages
import Home from './pages/home/Home'
import LoginSignupPage from './pages/loginSignup/LoginSignup'
import Account from './pages/account/Account'
import AccountGeneralTab from './pages/account/accountGeneralTab/AccountGeneralTab'
import AccountPostsTab from './pages/account/accountPostsTab/AccountPostsTab'
// import AccountCommentsTab from './pages/account/accountCommentsTab/AccountCommentsTab'
// import About from './pages/about/About'
import NotFound from './pages/notFound/NotFound'

// Modals
import LoginSignupModal from './modals/loginSignup/LoginSignup'
import CreateProblemSolution from './modals/createProblemSolution/CreateProblemSolution'
import ViewProblemSolution from './modals/viewProblemSolution/ViewProblemSolution'
import ViewProfile from './modals/viewProfile/ViewProfile'
import ResetPassword from './pages/resetPassword/ResetPassword'
import VerifyEmail from './pages/verifyEmail/VerifyEmail'
import ModerationNotice from './modals/moderationNotice/ModerationNotice'
import ReportForm from './modals/reportForm/ReportForm'

import { createProblemSolution, editProblemSolution } from './hooks/problemSolutionHooks'
import EditComment from './modals/editComment/EditComment'
import { useTheme } from './context/ThemeContext'
import PrivacyPolicy from './pages/legal/privacyPolicy/PrivacyPolicy'
import Legal from './pages/legal/Legal'
import TermsAndConditions from './pages/legal/termsAndConditions/TermsAndConditions'
import CookiePolicy from './pages/legal/cookiePolicy/CookiePolicy'
import AboutGeneralTab from './pages/about/aboutGeneralTab/AboutGeneralTab'
import About from './pages/about/About'
import EditUsername from './modals/editUsername/EditUsername'
import Contact from './pages/contact/Contact'
import ContactSuccess from './modals/contactSuccess/ContactSuccess'
import Settings from './pages/settings/Settings'

function App() {
  const location = useLocation()
  const { isLoggedIn, logout, currentModal, closeModal } = useAuth()
  const { theme } = useTheme()

  // const [isFirstLoad, setIsFirstLoad] = useState(true)

  // Check login status before first rendering the app
  // useEffect(() => {
  //   if (isFirstLoad) {
  //     console.log("FIRST LOAD: Checking login status")

  //     const checkStatus = async () => {
  //       const response = await checkLoginStatus()
  //       console.log(response)
  //       setIsFirstLoad(false)
  //     }
  //     checkStatus()
  //   }
  // }, [isFirstLoad])


  // Specific to MyAccount
  // const RedirectToPost = () => {
  //   const { ps_id } = useParams()
  //   return <Navigate replace to={`/post/${ps_id}`} />
  // }

  function LogoutHandler() {
    logout()
  }

  return (
    isLoggedIn === null ? // Login check // !isFirstLoad

    <div className="App" style={{height: '100vh', width: '100vw'}} data-theme={theme}>
      <Spinner />
    </div>

    :

    <div className={`App${currentModal ? ' modal-open' : ''}`} data-theme={theme}>

      <Header />

      <div className='main-content'>

        {/* Modals */}
        {currentModal.name === 'loginSignup' && (!isLoggedIn ? <LoginSignupModal /> : closeModal())}
        {currentModal.name === 'moderationNotice' && (isLoggedIn ? <ModerationNotice /> : closeModal())}
        {currentModal.name === 'reportForm' && (isLoggedIn ? <ReportForm /> : closeModal())}
        {currentModal.name === 'editComment' && (isLoggedIn ? <EditComment /> : closeModal())}
        {currentModal.name === 'editUsername' && (isLoggedIn ? <EditUsername /> : closeModal())}
        {currentModal.name === 'contactSuccess' && <ContactSuccess />}

        {/* {currentModal === 'viewProblemSolution' && <ViewProblemSolution />}
        {currentModal === 'viewProfile' && <ViewProfile />} */}

        <Routes>

          {/* Home */}
          <Route path='/' element={<Home />}>
            
            {/* <Route path="login" element={!isLoggedIn ? <LoginSignupModal /> : <Navigate replace to='/' />} /> */}
            <Route path="logout" element={isLoggedIn ? <LogoutHandler /> : <Navigate replace to='/' />} />
            <Route path="createproblemsolution" element={
              isLoggedIn ? 
              <CreateProblemSolution initialFormData={false} onSubmit={createProblemSolution} /> 
              : 
              <Navigate replace to='/login' />
            } />
            
            <Route path="post/:ps_id" element={<ViewProblemSolution />} />
            {/* {!isLoggedIn && <Route path="account/posts/all/:ps_id" element={<RedirectToPost />} />} For MyAccount */}
            {/* {!isLoggedIn && <Route path="account/posts/deleted/:ps_id" element={<RedirectToPost />} />} For MyAccount */}
            
            {/* Profile */}
            <Route path="profile/:username" element={<ViewProfile />}>
              <Route index element={<Navigate replace to='general' />} />

              <Route path="general" element={<ProfileGeneralTab />} />
              <Route path="posts" element={<ProfilePostsTab />} />
              <Route path="comments" element={<ProfileCommentsTab />} />
            </Route>
            {/* <Route path="about" element={<AboutUs />} /> */}
          </Route>


          {/* Login / Signup / Password Reset / Verify-Email */}
          <Route path="login" element={!isLoggedIn ? <LoginSignupPage /> : <Navigate replace to='/' />} />
          <Route path="signup" element={!isLoggedIn ? <LoginSignupPage /> : <Navigate replace to='/' />} />
          <Route path="login/reset-password" element={!isLoggedIn ? <LoginSignupPage /> : <Navigate replace to='/' />} /> {/* Request Password Reset */}          
          <Route path="account/reset-password" element={<ResetPassword />} /> {/* Reset Password */}
          <Route path="verify-email" element={<VerifyEmail />} />


          {/* Account */}
          <Route path="account" element={isLoggedIn ? <Account /> : <Navigate replace to='/login' />}>
            <Route index element={<Navigate replace to='/account/general' />} />

            {/* GENERAL */}
            <Route path="general" element={<AccountGeneralTab />} />

            {/* POSTS */}
            <Route path="posts" element={<Navigate replace to='/account/posts/all' />} />

            <Route path="posts/all" element={<AccountPostsTab />}>
              <Route path="edit/:ps_id" element={<CreateProblemSolution initialFormData={true} onSubmit={editProblemSolution} />} />

              {/* <Route path=":ps_id" element={<ViewProblemSolution />} /> */}
            </Route>

            <Route path="posts/deleted" element={<AccountPostsTab />} />

            {/* COMMENTS */}
            {/* <Route path="comments" element={<AccountCommentsTab />} /> */}
          </Route>

          {/* OTHER */}
          <Route path="about" element={<About />}>
            <Route index element={<Navigate replace to='/about/general' />} />
            <Route path="general" element={<AboutGeneralTab />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsAndConditions />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
          </Route>

          <Route path="contact" element={<Contact />} />
          <Route path="settings" element={<Settings />} />

          {/* <Route path="legal" element={<Legal />}>
            <Route index element={<Navigate replace to='/legal/privacy-policy' />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsAndConditions />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
          </Route> */}


          {/* <Route path='/about' element={<About />} /> */}

          {/* Must be logged in to view */}
          {/* <Route path='/account' element={isLoggedIn ? <Account /> : <Navigate replace to='/' />} /> */}

          {/* 404 Not Found */}
          <Route path='*' element={<NotFound />} />
        </Routes>

      </div>

      {location.pathname !== '/' &&
      !location.pathname.startsWith('/post/') &&
      !location.pathname.startsWith('/profile/') &&
      <Footer />}
      {/* {location.pathname !== '/' && <Footer />} */}
    </div>
  )
}

export default App

/*

// VIEWPORTS //////////////////////////////////////////////////////////////////////////////////////

  Mobile: 375px - 599px
  Tablet: 600px - 1023px (Using 768px as a common breakpoint for small views)
  Laptop: 1024px - 1279px
  Desktop: 1280px - 1980px



// REQUESTS ///////////////////////////////////////////////////////////////////////////////////////

  // Now ---

  Language Button

  Password Reveal in Admin-Panel Registration

  Prevent Logo swap on theme change


  // Later ---

  Include "Similar Posts" for searches that only come up with a few results

  (These will need heavy SCSS var changes)
  In desktop, taller navbar

  Double email/password input

  Add screen reader button for Posts (by share)



// QUESTIONS ///////////////////////////////////////////////////////////////////////////

  What should be the method of deletion and restoration of a Comment?
    - NO COMMENT TAB. No deletion, though editing is good. Doesn't matter if they edit to be a dot

  Any ideas for the AboutUs/Contact page, or still on the backburner?
    - Pending


// CORE FEATURES NEEDED ///////////////////////////////////////////////////////////////////////////


Planned -------------------------------------------------------------------------------------------

  Advertisements



Backburner ----------------------------------------------------------------------------------------
  
  CAPTCHA implementation
  
  Contact Page

  ProblemSolutions - Sorting / Filtering ( Use System in Admin-Panel )
  
  Profile Search



Conceptual ----------------------------------------------------------------------------------------  

  Replying to other replies (Either nesting or @Username)

  Profile View-History / Notifications (Just posts for history or also likes/comments?)

  Post pinning/saving

  Multi-Factor Authentication (MFA)

  Infinite scrolling comments



// TODO NOW ///////////////////////////////////////////////////////////////////////////////////////

  Use "#f3f3f3" and other colors from CatalystClone?

  "Add another feature to set default theme to users' system theme. That'd complete the UI"


Lighthouse -----------------------------------

  "Background and foreground colors do not have a sufficient contrast ratio" -- (Dates on Posts)

  "Heading elements are not in a sequentially-descending order" -- (Post titles)


General -----------------------------------------------

  PERFORMANCE: Optimize the number of requests to the server by refreshing AccessTokens mid-request (How to return though? Do we switch to using Cookies?)

  STANDARDS: Change AuthContext & Route paths to better reflect their HTTP Protocol

  STANDARDS: ADD GENERAL ACCESSIBILITY CONTROLS


Bugs ---------------------------------------------------

  MINOR: Reset comment (Read More) when open/close Post


  // Weird/Too much trouble ----
  BUG: Auto-close (out of view) for posts in MyAccount is broken (Refs are a total mess in general, MyAccount/Home both)
  BUG(?): Does Adblock screw with EventListener/Infinite Scrolling? (Potential issues for removing elements after Ref has been set)


  // Smaller Bugs ----
  MINOR: Posts don't refresh after a new one is created (Just make CreatePost its own page || Pass in "FetchPosts" as parameter?)
  MINOR: MyAccount scroll button does not fade in mobile view when opening a PS - (Temp removal to prevent clashing)
  MINOR: Clicking Post link in MyAccount leads to homepage
  MINOR: CloseModal backdrop partially blocked by ViewPS Container
  MINOR: Going back to an expired password reset token displays fine?



// TODO LATER /////////////////////////////////////////////////////////////////////////////////////
  
General -------------------------------------------------------------------------------------------
  
  // Concepts ////////////
  
  Textarea no longer resizeable, change back? // https://stackoverflow.com/questions/62201708/scroll-shows-above-css-textarea/62202237#62202237
  
  Rename saved tokens as something other than 'RefreshToken' ("...security risk, primarily due to its predictability")

  Find way to record users/ip that continually hit the rate limit?

  Change email/password limitation to use actual limitation code

  Add a spinner to bottom of Post list, disapears when returns "No more to load"
  
  Add 'show password' button to Password Reset

  Make a nice layout for emails

  Use 'isLoading' with requests to disable the submit button (anti-spam), not just to show a spinner

  Change home-page "Nothing Found!" / "Trending" Display (Especially when Fetch is failing). Looks jank

  Add shifting labels to login/signup/password-reset

  Put AccessToken in Cookies, and use jwtAuth comment code to auto renew mid-request?

  Use hidden links (in footer?) for SEO purposes

  Get "Likes Received" working (MyAccount / Profile)

  Change CreateProblemSolution Modal into a page

  Max-height for comments with a "read more" button (Check for Text Length AND/OR amount of <br> ?)

  Disable comment/submit buttons while pending

  Add "Last Edited" to posts & comments

  Leave Hidden posts unaltered aside from HIDDEN in the name. Make tooltip for reason?

  Make error handling more specific (Messages next to the problem area) (Return a message as well as "type: password"?)

  Make Email-Verification a separate page

  Remove un-verified account after a week

  Exclude ShadowBanned results for suggestions

  Make hidden posts exclusive to MyAccount page?

  Turn Profile Posts tab into a searching associated with the Username/ID? Would look much better

  Make Problem and Solution folder tabs?

  Mute expiration of 0 mean infinite?
  
  Improve on Rich Text input, styling included
    - Also add an HR button to RichText?
    - Also get Images/Links working nicely 

MyAccount -----------------------------------------------------------------------------------------

  Add box shadow



NOTES /////////////////////////////////////////////////////////////////////////////////////////////
  
  Video tutorial --> https://youtu.be/y99YgaQjgx4
  Goal demonstration - 0:00
  Base setup & functionality - 4:45
  Responsive CSS - 2:58:49
  Backend - 4:58:53 (Mongo creation @5:06:54) (ThunderClient @5:24:52)
  Backend Overview - 5:55:27
  Admin - 5:56:44
  Backend User Creation Overview - 7:35:38
  Backend User Login - 7:40:38
  Frontend Login/Signup - 7:49:43
  Loading backend products into store - 8:13:16
  "NewCollection" backend overview - 8:20:06
  NewCollections frontend start - 8:22:09
  Popular in women Backend - 8:26:53


THOUGHTS //////////////////////////////////////////////////////////////////////////////////////////

  Use Modals for most of the pages, with the Context listener for URL Query params changes
  utilizing a React useReducer of some sort to handle the modals and data fetching?

  Maybe modals for everything, with a reoccuring refresh fetch/animation for post changes? (W/Refresh button for manual)

  "Keep the dream in view while reading comments" (In mobile view the comments will remain in the dreambox)

  What colors should be used? - (Darker, maybe brown? Include theme options)
  Should most of the ui be rounded? - (Rounded is good)
  Should posts have their own page? - (Make it a Modal)
  What should account creation look like? - (Email input w/verification, username and double password input)
  What kind of information/functions should the MyAccount page have? - ()
  How should comment replies function? (Nested like Reddit or using @'s like Youtube?)
  How should notifications work, if at all?
  How should the searchbar work? (Only posts, or also profiles? Anything else?)
  Should comments be editable? - (Yes)
  Should there be a way to delete comments? - (No but have a report feature)
  What should the About page contain? - (Username w/basic info and tabs for post/comment/like history. Being logged in as the same user shows extra options/setting)
  Should there also be dislikes? - (No, but again have an option to report)

  Should the solution sharing comment be highlighted at the top, like in stackoverflow?
*/