import React, { useRef } from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom'
import ScrollButton from '../../components/scrollButton/ScrollButton'

// Very rough Account page concept, nothing here yet

// TABS --> Account/Stats, Preferences, ProblemSolutions, Comments/Replies, Notifications?


const Account = () => {
  const scrollRef = useRef(null)
  const location = useLocation()
  const isGeneralTab = location.pathname.split('/')[2] === "general"
  const isShowingDeleted = location.pathname.split('/')[3] === "deleted"

  // console.log(location.pathname.split('/')[3])

  // const selected_ps_id = useSelector(state => state.selected_ps_id)

  return (
    <div className='account-page page' ref={scrollRef}>
      
      <div className={`account-container ${isGeneralTab ? "is-general" : "is-posts"}`}>
        {/* HEADER */}
        <header className='account-header'>
          <h2 className='account-title'>My Account</h2>

          <nav className='account-tab-links-container'>
            <ul className='account-tab-links-list'>
              <li><NavLink to="/account/general" aria-label='Account General' className={({ isActive }) => isActive ? 'account-tab-link isActive link-is-btn' : 'account-tab-link link-is-btn'}>General</NavLink></li>
              <li><NavLink to="/account/posts/all" aria-label='Account Posts' className={({ isActive }) => isActive ? 'account-tab-link isActive link-is-btn' :  `account-tab-link link-is-btn ${isShowingDeleted && "isActive"}`}>Posts</NavLink></li>
              {/* <li><NavLink to="/account/comments" className={({ isActive }) => isActive ? 'account-tab-link isActive' : 'account-tab-link'}>Comments</NavLink></li> */}
              {/* <li><NavLink to="/account/pins" className={({ isActive }) => isActive ? 'account-tab-link isActive' : 'account-tab-link'}>Pins</NavLink></li> */}
              {/* <li><NavLink to="/account/settings" className={`account-tab-link ${({ isActive }) => isActive ? 'active' : ''} activeClassName="active">Settings</NavLink></li> */}
            </ul>
          </nav>
        </header>

        {/* CONTENT */}
        <section className='account-content'>
          <Outlet />
        </section>

        {/* FOOTER */}
        {isGeneralTab && <footer className='account-footer'></footer>}
        
      </div>

      {/* <ScrollButton scrollRef={scrollRef} value={200} />  */} {/* hideButton={selected_ps_id} */}
    </div>
  )
}

export default Account
