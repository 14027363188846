// TEST LOGO
import company_logo from '../../assets/colprosol-logo-2.webp'
import company_logo_light from '../../assets/colprosol-logo-1.webp'

// import company_logo from '../../assets/colprosol-logo6.png'
// import company_logo_light from '../../assets/colprosol-logo7.png'

import { useAuth } from '../../context/AuthContext'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Searchbar from '../searchbar/Searchbar'
import DropdownNav from '../dropdownNav/DropdownNav'
import { useTheme } from '../../context/ThemeContext'

const Header = () => {
  const location = useLocation()
  const { isLoggedIn } = useAuth()
  const { theme } = useTheme()
  // const [theme, setTheme] = useState('dark')

  return (
    <header className='header'>
      <div className='header-container'>
        {/* Logo */}
        <Link to="/" aria-label='Browse Posts' title="Browse Posts" className="header-logo-link">
          {theme !== 'light' ? 
            <img src={company_logo} alt="The ProblemSolver company logo" className='header-logo' />
          :
            <img src={company_logo_light} alt="The ProblemSolver company logo" className='header-logo' />
          }
        </Link>

        {/* Searchbar */}
        <Searchbar />

        <div className="header-button-container">          
          {/* {isLoggedIn ?
            // Account Button
            <NavLink to='/account/general' className={({ isActive }) => isActive ? 'header-link btn hide-in-tablet-view isActive' : 'header-link btn hide-in-tablet-view'}>My Account</NavLink>
            
            :

            // Login Button
            location.pathname !== '/login' && <NavLink to='/login' className='header-link btn hide-in-tablet-view'>Login</NavLink>
          } */}

          {/* Navigation Menu */}
          <DropdownNav />

          {/* Theme toggle button */}
          {/* <button 
          className='header-theme-button btn' 
          onClick={() => toggleTheme()}
          >
            {theme === 'dark' ? <i className="fa-solid fa-sun fa-lg"></i> : <i className="fa-solid fa-moon fa-lg"></i>}
          </button> */}

        </div>
      </div>
    </header>
  )
}

export default Header