import { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Spinner from '../spinner/Spinner'
import Reply from '../reply/Reply'
import LikeButton from '../likeButton/LikeButton'
import { adjustTextAreaHeight, containsBadWords, getTextCount, isEditorContentEmpty, isOverflowing, limitTextLength, removeExtraTextPBrs } from '../../helpers/helper'
import DropdownMenu from '../dropdownMenu/DropdownMenu'
import { createComment, getComments } from '../../hooks/commentHooks'
import RichTextWithToolbar from '../richTextWithToolbar/RichTextWithToolbar'
import { Link } from 'react-router-dom'

const Comment = ({id, ps_id, text, user, date, replyCount, likeCount, likedByUser, isSameUser, isHidden, updateCommentCallback}) => {
    const {checkLoginStatus, authCustomFetch, openModal, currentModal} = useAuth()
    const [loading, setLoading] = useState(false)

    // Reply states
    const [replyFormOpen, setReplyFormOpen] = useState(false)
    const [replyListOpen, setReplyListOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [replies, setReplies] = useState([])
    const [replyText, setReplyText] = useState('')
    const [prevModalWasEditComment, setPrevModalWasEditComment] = useState(false)

    const [readMore, setReadMore] = useState(false)
    const [overflowing, setOverflowing] = useState(true)

    // const textareaRef = useRef()
    const commentTextRef = useRef()
    const replyRef = useRef()

    // Function to load comments for the selected PS
    const handleGetReplies = async () => {
        // console.log("GETTING REPLIES")
        setLoading(true)

        const response = await getComments({
            searchBy: {parent_id: id}
        }, authCustomFetch)

        // console.log(response)

        setReplies(response)
        setLoading(false)
    }

    // Load comments whenever the selected PS changes
    useEffect(() => {
        if (replyListOpen) handleGetReplies() // || replies.length === 0
    }, [replyListOpen])

    // For updating replies after editing
    useEffect(() => {
        console.log("currentModal", currentModal)
        if (currentModal.name === "editComment") setPrevModalWasEditComment(true)
        else if (!currentModal && prevModalWasEditComment && replyListOpen) handleGetReplies()
        else setPrevModalWasEditComment(false)
    }, [currentModal])



    // Open Reply Form handler
    const handleOpenReplyForm = () => {
        setReplyFormOpen(true)
        setTimeout(() => replyRef.current?.focus(), 0) // setTimeout is used to ensure the call is pushed to the end of the event queue
        // textareaRef.current.focus()
    }
    // Close Reply Form handler
    const handleCloseReplyForm = () => {
        setReplyText('')
        setReplyFormOpen(false)
    }

    // Reply Form Change handler
    const maxTextCharacters = 1000 // NEED INDICATOR
    const changeHandler = (e) => {
        const text = e
        if (getTextCount(text) <= maxTextCharacters) setReplyText(text)

        // const { value } = e.target
        // setReplyText(limitTextLength(value, maxTextCharacters))
        // adjustTextAreaHeight(e.target)
    }

    // Reply List Toggle
    const handleReplyListToggle = () => {
        setReplyListOpen(!replyListOpen)
        // if (!replyListOpen) setReplies([])
    }

    // Reply submit handler
    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!checkLoginStatus()) return

        if (containsBadWords(replyText)) {
            setErrorMessage("Please remove any profanity from the Comment")
            return
        }

        if (isEditorContentEmpty(replyText)) {
            setErrorMessage("Comments cannot be empty")
            replyRef.current.getEditor().focus()
            return
          }
      
          const trimmedReply = removeExtraTextPBrs(replyText)

        // Sending the request
        const response = await createComment({
            ps_id: ps_id,
            parent_id: id,
            text: trimmedReply,
        }, 
        authCustomFetch, 
        openModal)

        // console.log(response)

        // If the request is successful, refresh the replies and close the form
        if (response.success) {
            handleGetReplies() // Refresh replies
            handleCloseReplyForm() // Reset form
            setReplyListOpen(true)

            updateCommentCallback(prev => prev + 1)
      
            setErrorMessage("")
            // navigate("/")
        } 
        else setErrorMessage(response.error || response.message)

    }

    useEffect(() => {
        // if (!commentTextRef.current) return
    
        const isContentOverflowing = isOverflowing(commentTextRef.current)
        setOverflowing(isContentOverflowing)

    }, [commentTextRef, text]) // Add text dependency to re-check on text change

  return (
    <div className='comment'>
        {/* Username and date */}
        <div className='comment-user-date'>
            <p className='comment-user'><Link to={`/profile/${user}`} className='text-secondary'>{user}</Link></p>
            <p className='comment-date text-third'>- {date}</p>
            <DropdownMenu id={id} type='Comment' createdBySelf={isSameUser} />
        </div>


        {/* Comment text */}
        {/* <p className={`comment-text ${isHidden && 'text-secondary'}`}>{text}</p> */}
        <div className="comment-text-container">
            <div ref={commentTextRef} className={`comment-text ${isHidden ? 'text-secondary' : ''} ${readMore ? 'read-more' : ''}`} dangerouslySetInnerHTML={{ __html: text }}></div>
            {!readMore && overflowing && <button onClick={() => setReadMore(true)} className='button-link'>Read More ...</button>}
        </div>

        <div className='comment-stats'>

            <div className={`comment-buttons ${replyFormOpen && 'absolute'}`}>

                {/* Likes + Toggle Button */}
                {!isHidden && <LikeButton id={id} parentType='comment' likeCount={likeCount} likedByUser={likedByUser} />}

                {/* Show/Hide Replies*/}
                {(replies.length > 0 || replyCount > 0) && 
                <div className={`comment-replies`}> {/* ${replyListOpen && 'flip-carat'} */}
                    <button aria-label='Toggle Replies' onClick={handleReplyListToggle} className={`comment-replies-button button-small button-with-icon ${replyListOpen && 'toggled'}`}>
                        <span>Replies</span> <i className="fa-solid fa-comment"></i> <span style={{margin: '-3px 3px 0px 3px'}}>|</span> {replies.length || replyCount}
                        {/* <i className="fa-solid fa-caret-down"></i> */}
                    </button>
                </div>}

                {/*When creating a reply, we want it to create a textbox at the top of the current
                reply list. Need to figure out what to do if none are there prior.*/}

                {/* Create Reply Button */}
                {!replyFormOpen && !isHidden &&
                <div className='comment-reply'>
                    <button aria-label='Submit Reply' onClick={handleOpenReplyForm} className='button-small'>Reply</button> 
                </div>}
            </div>

            {(replies.length > 0 || replyCount > 0 || replyFormOpen) && 
            <div className='comment-stats-lower'>
                {/* Reply Form */}
                {replyFormOpen && 
                <form className='reply-form' onSubmit={handleSubmit}>

                    <RichTextWithToolbar
                    name='reply-text'
                    placeholderText='Add a reply...'
                    className='reply-form-text'
                    text={replyText}
                    setText={changeHandler}
                    ref={replyRef}
                    />

                    {/* <textarea 
                    ref={textareaRef} 
                    type="text" 
                    placeholder='Add a reply...' 
                    name='reply-textarea' 
                    value={replyText}
                    onChange={changeHandler}
                    // required
                    /> */}

                    <div className='reply-form-indicator'>
                        <p className='error-message text-danger'>{errorMessage}</p>
                        {/* <p className='character-count'>{replyText.length} / {maxTextCharacters}</p> */}
                        <p className='character-count'>{getTextCount(replyText)} / {maxTextCharacters}</p>
                    </div>

                    <div className='reply-form-buttons'>
                        <button aria-label='Cancel Reply' type='button' onClick={handleCloseReplyForm} className='button-small'>Cancel</button>
                        <button aria-label='Submit Reply' type='submit' className='button-small'>Submit</button>
                    </div>
                </form>}

                
            </div>}
        </div>

        <div className={`comment-reply-list ${replyListOpen && 'open'}`}>
            <hr />

            {/* REPLY PRINTER */}
            {replies && replies?.length > 0? 
                replies.map(reply => (
                    <Reply 
                    key={reply._id} 
                    id={reply._id}
                    ps_id={reply.ps_id}
                    parent_id={reply.parent_id}

                    isSameUser={reply.isSameUser}
                    isHidden={reply.isHidden?.active}
                    user={reply.user} 
                    date={reply.date}

                    text={reply.text}

                    likeCount={reply.likeCount}
                    likedByUser={reply.likedByUser}
                    />
                ))

                : 
                
                loading? <Spinner /> : <h4>Nothing yet...</h4>
            }
        </div>
    </div>
  )
}

export default Comment