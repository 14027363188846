const baseURL = process.env.REACT_APP_BACKEND_URL


// REQUEST PASSWORD RESET
export const requestPasswordReset = async (email) => {
    try {
        const response = await fetch(`${baseURL}/request_password_reset`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        })

        const data = await response.json()
        // console.log(data)

        // if (!data.success) console.error("ERROR WHILE REQUESTING PASSWORD RESET: " + data.error || data.message)

        return data

    } catch (error) {
        return { success: false, error: error.message}
    }
}

// VERIFY PASSWORD RESET TOKEN
export const verifyPasswordResetToken = async (token) => {
    try {
        const response = await fetch(`${baseURL}/verify_password_reset_token?token=${encodeURIComponent(token)}`, {
            method: 'GET',
        })

        const data = await response.json()
        // console.log(data)

        // if (!data.success) console.error("ERROR WHILE VERIFYING PASSWORD RESET TOKEN: " + data.error || data.message)

        return data

    } catch (error) {
        return { success: false, error: error.message}
    }
}

// RESET PASSWORD
export const resetPassword = async (token, newPassword) => {
    try {
        const response = await fetch(`${baseURL}/reset_password`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify({ token, newPassword })
        })

        const data = await response.json()
        // console.log(data)

        // if (!data.success) console.error("ERROR WHILE RESETTING PASSWORD: " + data.error || data.message)

        return data

    } catch (error) {
        return { success: false, error: error.message}
    }
}