const baseURL = process.env.REACT_APP_BACKEND_URL

// SEND CONTACT FORM
export const sendContactForm = async ({name, email, reason, message}, authCustomFetch) => {
    try {
        const response = await authCustomFetch(`${baseURL}/contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": `Bearer ${token}` // This is automatically added by "customFetch"
            },
            body: JSON.stringify({name, email, reason, message})
        })

        const data = await response.json()
        // console.log(data)

        // Response/Error Messaging
        if (!data.success) {
            // console.error("ERROR SENDING CONTACT FORM: " + data.message || data.error)
        }

        return data

    } catch (error) {
        return { success: false, error: error.message }
    }
}