import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { toggleLike } from '../../hooks/miscHooks'

const LikeButton = ({id, parentType, likeCount, likedByUser}) => {
  const { checkLoginStatus, authCustomFetch } = useAuth()

  const [liked, setLiked] = useState(likedByUser)
  const [likes, setLikes] = useState(likeCount)


  const handleLike = async () => {
    
    if (!checkLoginStatus()) return

    const response = await toggleLike(id, parentType, authCustomFetch)
    const isLiked = response.liked

    if (isLiked === null) return

    setLikes(isLiked ? (likedByUser? likeCount : likeCount + 1) : (likedByUser? likeCount - 1 : likeCount))
    setLiked(isLiked)
  }

  return (
  <div className='likebutton-container'>
      <button aria-label='Toggle Like' name='like-toggle' onClick={handleLike} className={`likebutton button-small button-with-icon ${liked ? 'toggled' : ''}`}> {/* FOR TESTING ONLY, REMOVE LATER !!!! */}
          <span>{liked ? 'Liked' : 'Like'}</span> <i className={`${liked ? 'fa-solid' : 'fa-regular'} fa-thumbs-up`}></i> <span style={{margin: '-3px 3px 0px 3px'}}>|</span> <span>{likes}</span>
      </button>

      {/* <p>{likes}</p> */}
  </div>
  )
}

export default LikeButton
