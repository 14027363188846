import { useEffect, useRef, useState } from 'react'
import DropdownMenu from '../dropdownMenu/DropdownMenu'
import LikeButton from '../likeButton/LikeButton'
import { isOverflowing } from '../../helpers/helper'
import { Link } from 'react-router-dom'

const Reply = ({id, user, text, date, likeCount, likedByUser, isHidden, isSameUser}) => {

  const [readMore, setReadMore] = useState(false)
  const [overflowing, setOverflowing] = useState(true)

  const replyTextRef = useRef(null)

  useEffect(() => {
    // if (!commentTextRef.current) return

    const isContentOverflowing = isOverflowing(replyTextRef.current)
    setOverflowing(isContentOverflowing)


}, [replyTextRef, text]) // Add text dependency to re-check on text change

  return (
    <div className='reply'>
      <div className='reply-user-date'>
        <p className='reply-user'><Link to={`/profile/${user}`} className='text-secondary'>{user}</Link></p> 
        <p className='reply-date text-secondary'>- {date}</p>
        <DropdownMenu id={id} type='Comment' createdBySelf={isSameUser} />
      </div>
      
      {/* <p className={`reply-text ${isHidden ? 'text-secondary' : ''}`}>{text}</p> */}
      <div className="reply-text-container">
            <div ref={replyTextRef} className={`reply-text ${isHidden ? 'text-secondary' : ''} ${readMore ? 'read-more' : ''}`} dangerouslySetInnerHTML={{ __html: text }}></div>
            {!readMore && overflowing && <button onClick={() => setReadMore(true)} className='button-link'>Read More ...</button>}
        </div>

      {/* Likes + Toggle Button */}
      {!isHidden && <LikeButton id={id} parentType={'reply'} likedByUser={likedByUser} likeCount={likeCount} />}

    </div>
  )
}

export default Reply
