const baseURL = process.env.REACT_APP_BACKEND_URL

// CREATE COMMENT
export const createComment = async ({ps_id, parent_id, text}, authCustomFetch, openModal) => {
  try {
    // console.log(parent_id)
    
    const response = await authCustomFetch(`${baseURL}/create_comment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ps_id, parent_id, text})
    })

    const data = await response.json()
    // console.log(data)

    // Response/Error Messaging
    if (!data.success) {
      // console.error("ERROR IN COMMENT CREATION: " + (data.message || data.error))

      if (data.isMuted) {
        openModal({name: "moderationNotice", message: data.message, reason: data.reason, expiration: data.expiration})
        // return { success: false, message: "You are currently muted and cannot create a Comment." }
      }
    }

    return data

  } catch (error) {
    return { success: false, error: error.message}
  }
}

// EDIT COMMENT
export const editComment = async ({comment_id, text}, authCustomFetch, openModal) => {
  try {
    // console.log(parent_id)
    
    const response = await authCustomFetch(`${baseURL}/edit_comment/${comment_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({text})
    })

    const data = await response.json()
    // console.log(data)

    // Response/Error Messaging
    if (!data.success) {
      // console.error("ERROR IN COMMENT CREATION: " + (data.message || data.error))

      if (data.isMuted) {
        openModal({name: "moderationNotice", message: data.message, reason: data.reason, expiration: data.expiration})
        // return { success: false, message: "You are currently muted and cannot create a Comment." }
      }
    }

    return data

  } catch (error) {
    return { success: false, error: error.message}
  }
}


// GET COMMENTS
export const getComments = async (searchQuery, authCustomFetch) => {
  try {
    const response = await authCustomFetch(`${baseURL}/get_comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: searchQuery
      })
    })

    // Response/Error Messaging
    const data = await response.json()
    // console.log(data)

    // if (!data.success) console.error("ERROR IN COMMENT RETREIVAL: " + data.message || data.error)

    return data

  } catch (error) {
    return { success: false, error: error.message}
  }
}

// GET USER COMMENTS AND REPLIES (Combine with above)
export const getUserCommentsAndReplies = async (username, authCustomFetch) => {
  try {
    const url = `${baseURL}/get_user_comments_and_replies?username=${encodeURIComponent(username)}`
    const response = await authCustomFetch(url, { method: 'GET' })

    // Response/Error Messaging
    const data = await response.json()
    // console.log(data)

    // if (!data.success) console.error("ERROR IN USER COMMENT AND REPLY RETREIVAL: " + data.error)

    return data

  } catch (error) {
    return { success: false, error: error.message}
  }
}