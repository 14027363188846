import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'
import { getUserActivity } from '../../../hooks/userHooks'

const ProfileGeneralTab = () => {

  const { authCustomFetch, isLoggedIn } = useAuth() //viewProfile
  const [isLoading, setIsLoading] = useState(true)
  const [userActivity, setUserActivity] = useState( //{
  //   date_created: '',
  //   problemSolutionCount: 0,
  //   commentCount: 0,
  //   likesGiven: 0,
  //   receivedCommentCount: 0,
  //   receivedLikeCount: 0,
  // }
  )
  const {username} = useParams()

  useEffect(() => {
    const fetchUserActivity = async () => {
      const response = await getUserActivity(username, authCustomFetch)

      // console.log(response)


      if (response.success !== false) setUserActivity(...response)

      setIsLoading(false)
    }
    fetchUserActivity()
  }, [isLoggedIn, username])

  return (
    isLoading ? <Spinner /> :

    !userActivity ? <h1 className='nothing-found'>User could not be found</h1> :

    <div className='profile-general-tab profile-category-tab'>
      {/* <h1>General</h1> */}
      {/* <div className="profile-user-bio">
        <h4 className='profile-user-bio-title'>User Bio</h4>
        <p className='profile-user-bio-text'>HELLO</p>
      </div> */}

      <div className="profile-user-stats">
        {/* <h3>Overview</h3>
        <p>Member since - {userActivity?.date_created}</p>

        <hr />

        <p>Written Posts - {userActivity?.problemSolutionCount}</p>
        <p>Written Comments - {userActivity?.commentCount}</p>

        <hr />

        <p>Received Comments - {userActivity?.receivedCommentCount}</p>
        <p>Received Likes - {userActivity?.receivedLikeCount}</p>

        <hr />
        <br /> */}

        {/* <h3>(Later area for settings?)</h3> */}
        <h4 className="account-overview-title">Overview</h4>

        <hr />

        <ul>
            <li><span>Posts Written:</span> <span>{userActivity?.problemSolutionCount}</span></li>
            <li><span>Comments Written:</span> <span>{userActivity?.commentCount}</span></li>
            {/* <li><span>Likes Given:</span> <span>{userActivity?.likesGiven}</span></li> */}
            {/* <li><span>Likes Received:</span> <span>{userActivity?.likesReceived}</span></li> */}
            <li><span>Registered On:</span> <span>{userActivity?.date_created}</span></li>
        </ul>
      </div>

    </div>    
  )
}

export default ProfileGeneralTab
