import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'

// Nothing here yet
const About = () => {
  const location = useLocation()

  const currentPage = location.pathname.replace('/about/', '')
  const pageTitle = (
    currentPage === 'privacy-policy' ? 'Privacy Policy' :
    currentPage === 'terms-of-service' ? 'Terms of Service' :
    currentPage === 'cookie-policy' ? 'Cookie Policy' :
    'About Us'
  ) 

  return (
    <div className='about-page page'>
      <div className='about-container'>

        <header className='about-header'>
          <h1 className='about-title'>{pageTitle}</h1>

          {/* <hr /> */}

          {/* <nav className='about-tab-links-container'>
            <ul className='about-tab-links-list'>
              <li><NavLink to="/about/general" aria-label='General' className={({ isActive }) => isActive ? 'about-tab-link isActive link-is-btn' : 'about-tab-link link-is-btn'}>General</NavLink></li>
              <li><NavLink to="/about/privacy-policy" aria-label='Privacy Policy' className={({ isActive }) => isActive ? 'about-tab-link isActive link-is-btn' : 'about-tab-link link-is-btn'}>Privacy</NavLink></li>
              <li><NavLink to="/about/terms-of-service" aria-label='Terms of Service' className={({ isActive }) => isActive ? 'about-tab-link isActive link-is-btn' : 'about-tab-link link-is-btn'}>Terms</NavLink></li>
              <li><NavLink to="/about/cookie-policy" aria-label='Cookie Policy' className={({ isActive }) => isActive ? 'about-tab-link isActive link-is-btn' : 'about-tab-link link-is-btn'}>Cookies</NavLink></li>
            </ul>
          </nav> */}
        </header>
        
        <div className='about-content'>
          <Outlet />
        </div>

        <footer className='about-footer' />

      </div>
    </div>
  )
}

export default About
