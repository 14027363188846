import { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { containsBadWords, limitTextLength, validatePassword } from '../../helpers/helper'
import { requestPasswordReset } from '../../hooks/passwordHooks'

const LoginSignupForm = ({type, state, changeTitleCallback}) => {

    const { login, signup } = useAuth()
    const navigate = useNavigate()

    const [errorMessage, setErrorMessage] = useState("")
    const [submitFailed, setSubmitFailed] = useState(false)
    const [requiresVerification, setRequiresVerification] = useState(false)

    const [isModal, setIsModal] = useState(type === "modal")
    const [showPassword, setShowPassword] = useState(false)
    const [formInput, setFormInput] = useState({
        username: "",
        password: "",
        email: "",
        dateOfBirth: "",
        policyAgreement: false
    })
    
    // Handles Login/Signup submission
    const handleSubmit = async (e) => {
        e.preventDefault()

        // Sends data to AuthContext
        let response
        if (state === "Login") {
            const {email, password} = formInput
            response = await login(email, password)

            const {success, isEmailVerified} = response

            if (success === true && isEmailVerified === false) {
                setRequiresVerification(true)
                handleState("Email Verification")
            }
        }
        else if (state === "Sign Up") {
            const {username, password, policyAgreement, dateOfBirth} = formInput
            // console.log(formInput)

            if (containsBadWords(username)) {
                setErrorMessage("Please remove any profanity from your Username")
                return
            } 

            else if (username.length < 3) {
                setErrorMessage("Username must be at least 3 characters long")
                return
            }

            else if (!dateOfBirth) {
                setErrorMessage("Please enter your Date of Birth")
                return
            }

            else if (dateOfBirth > new Date()) {
                setErrorMessage("Date of Birth must be before today")
                return
            }

            else if (!policyAgreement) {
                setErrorMessage("You must agree to the Terms of Service and Privacy Policy to continue")
                return
            }

            const {success, message} = validatePassword(password, null)

            if(success) response = await signup(formInput.username, formInput.email, formInput.password, formInput.dateOfBirth)

            if (response.success === true) {
                setRequiresVerification(true)
                handleState("Email Verification")
            }
            else if (!response) response = success? '' : message
            
        }
        else if (state === "Reset Password") {
            const {email} = formInput

            if (!email) {
                setErrorMessage("Please enter your Email Address")
                return
            } 

            response = await requestPasswordReset(email)

            if (response.success === true) {
                setRequiresVerification(true)
                handleState("Password Reset Email")
            }
        }
        
        
        // If an error is returned, set the error message
        // console.log(response) 
        const {success, error=""} = response

        if (!success) {
            setErrorMessage(error)
            setSubmitFailed(true)
        }
    }
    
    // Swaps Modal between Login and Sign Up
    useEffect(() => {
        // console.log(state)
        setErrorMessage("")
        setSubmitFailed(false)
        if (state !== "Email Verification" && state !== "Password Reset Email") setRequiresVerification(false)
    }, [state])

    const handleState = (newState) => {
        changeTitleCallback(newState)        
    }

    // Records form data
    const changeHandler = (e) => {
        const maxCharacters = e.target.name === "username" ? 30 : 50
        setFormInput({...formInput, [e.target.name]: limitTextLength(e.target.value, maxCharacters)})
    }

    // Validates the passwords during SignUp
    // useEffect(() => {
    //     if (state === "Sign Up") {
    //         const {username, password} = formInput

    //         if (containsBadWords(username)) {
    //             setErrorMessage("Please remove any profanity from your Username")
    //         }    
    //         else if (password) {
    //             const {success, message} = validatePassword(password, null)
    //             console.log(success, message)
    //             setErrorMessage(success? '' : message)
    //         }
    //         else setErrorMessage("")
    //     }
    // }, [formInput])

    return ( requiresVerification ? 
    
    // Verification Reminder
    <div className='loginsignupform-verification'>
        <hr />
        <p>We've sent a message to your email address</p>
        <p>Please check your inbox for a link to {state === "Password Reset Email" ? "reset your password" : "verify your account"}</p>
        <p className='text-secondary'>(If you don't see it, please try logging in again in a few minutes)</p>
        <hr />

        <div className="loginsignup-state-swapper">
            <p>Return to Login <span onClick={() => {
                handleState("Login")
                if (!isModal) navigate("/login")
            }}>Click here</span></p>
        </div>
    </div>

    :

    // Login/Signup Form
    <div className='loginsignupform'>

        <hr />

        {/* Form */}
        <form className={`loginsignup-form${submitFailed && " loginsignup-error"}`} onSubmit={handleSubmit}>

            {/* Username (For registration only) */}
            {state === "Sign Up" && <>
                <label htmlFor="username" className="text-secondary">Username</label>
                <input name="username" value={formInput.username} onChange={changeHandler} type="text" /> {/* placeholder='Enter your Username...' */}
            </>}

            {/* Email */}
            <label htmlFor="email" className="text-secondary">Email Address</label>
            <input name="email" value={formInput.email} onChange={changeHandler} type="email" /> {/* placeholder='Enter your Email Address...' */}

            {/* Error Message (If present) */}
            {errorMessage && <p className='loginsignup-error-text'>{errorMessage}</p>}

            {/* Password */}
            {state !== "Reset Password" && <>
                <label htmlFor="password" className="text-secondary">Password</label>
                <div className="password-input-container">
                    <input name="password" value={formInput.password} onChange={changeHandler} type={showPassword ? "text" : "password"} /> {/* placeholder='Enter your Password...' */}

                    <button aria-label='Toggle Password Visibility' name='show-password-toggle' type="button" onClick={() => setShowPassword(!showPassword)} className="password-visibility-toggle">
                        {showPassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}
                    </button>
                </div>
            </>}            

            {/* Date of Birth (For registration only) */}
            {state === "Sign Up" && (
                <>
                    <label htmlFor="dateOfBirth" className="text-secondary">Date of Birth</label>
                    <input 
                        name="dateOfBirth" 
                        className='date-of-birth-input'
                        value={formInput.dateOfBirth} 
                        onChange={changeHandler} 
                        type="date" 
                        max={new Date().toISOString().split('T')[0]} // Set max date to today
                    />
                </>
            )}


            <button aria-label='Continue' name='login-signup-submit' type='submit'>Continue</button>

            <hr />

            {/* Terms/Privacy policy checkbox */}
            {state === "Sign Up" && <div className="loginsignup-agree">
                <input type="checkbox" name='policy-agreement' id='policy-agreement' onChange={() => setFormInput({...formInput, policyAgreement: !formInput.policyAgreement})} />
                <p>By continuing, you agree to our <span><Link to="/legal/terms-of-service" aria-label='Terms of Service' target="_blank">Terms of Service</Link></span> and <span><Link to="/legal/privacy-policy" aria-label='Privacy Policy' target="_blank">Privacy Policy</Link></span></p>
                {/* <p>By continuing, you agree to our <span><Link to="/legal/terms-of-service" aria-label='Terms of Service'>Terms of Service</Link></span> and <span><Link to="/legal/privacy-policy" aria-label='Privacy Policy'>Privacy Policy</Link></span></p> */}
                {/* <p>By continuing, you agree to our <span><Link to="/terms" aria-label='Terms of Service'>Terms of Service</Link></span> and <span><Link to="/privacy" aria-label='Privacy Policy'>Privacy Policy</Link></span></p> */}
            </div>}
        </form>


        {/* Login / Register swapper */}
        {isModal?

            // For Modal
            <div className="loginsignup-state-swapper">

                {state === "Login" && <p>Need to create an account? <span onClick={() => handleState("Sign Up")}>Click here</span></p>}
                {state === "Login" && <p>Forgot your password? <span onClick={() => handleState("Reset Password")}>Click here</span></p>}

                {state === "Sign Up" && <p>Already have an account? <span onClick={() => handleState("Login")}>Click here</span></p>}

                {state === "Reset Password" && <p>Return to Login <span onClick={() => handleState("Login")}>Click here</span></p>}
            </div>

            :

            // For Page
            <div className="loginsignup-state-swapper">

                {state === "Login" && <p>Need to create an account? <Link to="/signup" aria-label='Sign Up'>Click here</Link></p>}
                {state === "Login" && <p>Forgot your password? <Link to="/login/reset-password" aria-label='Reset Password'>Click here</Link></p>}

                {state === "Sign Up" && <p>Already have an account? <Link to="/login" aria-label='Login'>Click here</Link></p>}

                {state === "Reset Password" && <p>Return to Login <Link to="/login" aria-label='Login'>Click here</Link></p>}
            </div>
        }        
    </div>
    )
}

export default LoginSignupForm
