import { useRef, useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import { containsBadWords, getTextCount, isEditorContentEmpty, limitTextLength, removeExtraTextPBrs } from '../../helpers/helper'
// import { editUsername, getComments } from '../../hooks/commentHooks'
import Spinner from '../../components/spinner/Spinner'
import { editUsername } from '../../hooks/userHooks'

const EditUsername = () => {
    const { currentModal, closeModal, openModal, authCustomFetch } = useAuth()
    // const [submitSuccess, setSubmitSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [formText, setFormText] = useState(currentModal.username)
    const [isLoading, setIsLoading] = useState()
    const usernameRef = useRef(null)

    // Getting the Username
    // useEffect(() => {
    //     const handleGetCurrentUsername = async () => {
    //         // console.log("GETTING USERNAME")
    //         setIsLoading(true)
    
    //         const response = await getCurrentUsername({}, authCustomFetch)

    //         console.log(response)

    //         if (response?.length === 0) {
    //             setErrorMessage("Username not found")
    //             return
    //         }
    
    //         setUsername(response[0])
    //         setFormText(username.text)

    //         setIsLoading(false)
    //     }

    //     handleGetCurrentUsername()

    // }, [currentModal.object_id])

    const submitUsername = async (e) => {
        e.preventDefault()

        // if (!checkLoginStatus()) return

        const {object_id, type} = currentModal

        if (formText.length < 3) {
            setErrorMessage("Username must be at least 3 characters")
            usernameRef.current.focus()
            return
        }

        if (containsBadWords(formText)) {
            setErrorMessage("Please remove any profanity from the Username")
            usernameRef.current.focus()
            return
        }

        if (isEditorContentEmpty(formText)) {
            setErrorMessage("Username cannot be empty")
            usernameRef.current.focus()
            return
        }
      
        // const trimmedText = removeExtraTextPBrs(formText)

        const response = await editUsername({
            newUsername: formText, 
            // user_id: object_id,
        }, 
        authCustomFetch,
        openModal)

        console.log(response)

        if (response.success) closeModal()
        
        else setErrorMessage(response?.message || response.error || "An error occurred")
    }

    const maxCharacters = 30
    const handleInputChange = (e) => {
        const { value } = e.target
        setFormText(limitTextLength(value, maxCharacters))
        // if (getTextCount(text) <= maxCharacters) setFormText(text)
    }
    
    
    return (  // Displays when "login" is in the url query params
    <div className={`editusername modal`}>
        <div className='modal-overlay' onClick={() => closeModal()}>
            {isLoading && <Spinner />}
        </div>

        {!isLoading && <div className={`editusername-container modal-container`}>
            {/* Header */}
            <header className="editusername-header">
                {/* Title */}
                <h1>Edit Username</h1>

                {/* Close Button */}
                <button aria-label='Close Modal' name='close-modal-button' className="modal-close-button button-small" onClick={() => closeModal()}>
                    <i className="fa-solid fa-x"></i>
                </button>
            </header>

            <hr />
                
            <form className="editusername-form" onSubmit={submitUsername}>

                <label htmlFor="editusername-text" className="text-secondary" aria-label='Username'>Username</label>
                <input name="editusername-text" className='editusername-form-text' value={formText} onChange={handleInputChange} type="text" ref={usernameRef}/>

                
                <div className='editusername-form-indicator'>
                    <p className='error-message text-danger'>{errorMessage}</p>
                    <p className='character-count'>{getTextCount(formText)} / {maxCharacters}</p>
                </div>

                <button aria-label='Submit Username' name='submit-button' type='submit'>Submit Username</button>
            </form>

        </div>}
    </div>
    )
}

export default EditUsername
