import { useEffect, useState, useRef } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'
import { closeModal, containsBadWords, isEditorContentEmpty, getTextCount, removeExtraTextPBrs, limitTextLength } from '../../helpers/helper'
import RichTextWithToolbar from '../../components/richTextWithToolbar/RichTextWithToolbar'
import Spinner from '../../components/spinner/Spinner'
import { getProblemSolutions } from '../../hooks/problemSolutionHooks'

const CreateProblemSolution = ({initialFormData, onSubmit}) => {
  const { authCustomFetch, openModal, closeModal } = useAuth()
  const navigate = useNavigate()

  // const [isOnLastTab, setIsOnLastTab] = useState(false)
  const [showProblem, setShowProblem] = useState(true)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [formData, setFormData] = useState({
    title: "",
    problem: "",
    solution: "",
  })

  const titleRef = useRef()
  const problemRef = useRef()
  const solutionRef = useRef()

  const fetchProblemSolution = async () => {
    setLoading(true)

    // console.log("MAKING REQUEST")
    const response = await getProblemSolutions({
      query: {
        searchBy: {edit_id: ps_id}
      }
    }, authCustomFetch)

    // console.log(response)

    if (response.list?.length > 0) {
      const {title, problem, solution} = response.list[0]
  
      setFormData({
        title: title, 
        problem: problem,
        solution: solution
      })
    }
    else setNotFound(true)

    setLoading(false)
  }

  const {ps_id = false} = useParams()
  useEffect(() => {
    // console.log(ps_id)
    
    if (initialFormData && ps_id) fetchProblemSolution() // Needs error handling for when the PS to be edited wasn't found!
    else setLoading(false)
  }, [])

  useEffect(() => {
    if (!loading && !notFound) titleRef.current.focus()
  }, [loading])

  const maxTitleCharacters = 120
  const maxTextCharacters = 5000

  // Form Change Handlers
  const titleHandler = (e) => {
    // console.log(e)
    const { name, value } = e.target
    setFormData({ ...formData, [name]: limitTextLength(value, maxTitleCharacters) })
  }

  // const problemHandler = (problemHtml) => {
  //   if (getTextCount(problemHtml) <= maxTextCharacters) {
  //     setFormData({ ...formData, problem: problemHtml })
  //   }
  // }
  
  // const solutionHandler = (solutionHtml) => {
  //   if (getTextCount(solutionHtml) <= maxTextCharacters) {
  //     setFormData({ ...formData, solution: solutionHtml })
  //   }
  // }

  const problemHandler = (problemHtml) => {
    const textCount = getTextCount(problemHtml)
    if (textCount <= maxTextCharacters && formData.problem !== problemHtml) {
      setFormData({ ...formData, problem: problemHtml })
    }
  }
  
  const solutionHandler = (solutionHtml) => {
    const textCount = getTextCount(solutionHtml)
    if (textCount <= maxTextCharacters && formData.solution !== solutionHtml) {
      setFormData({ ...formData, solution: solutionHtml })
    }
  }

  // Handles next button
  // const handleNext = (e) => {
  //   e.preventDefault()
  //   setIsOnLastTab(true)
  // }

  const closeModalLocally = () => {
    const searchParams = new URLSearchParams(window.location.search)
    navigate(initialFormData? "/account/posts/all" : "/" + searchParams.toString())
  }

  // Handles submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault()

    // Checking if the title field is filled in
    if (formData.title.trim() === "") {
      setErrorMessage("Please fill in the Title field")
      titleRef.current.focus()
      return
    }

    // Checking if the problem field is filled in
    if (isEditorContentEmpty(formData.problem)) {
      setShowProblem(true)
      setErrorMessage("Please fill in the Problem field")
      problemRef.current.getEditor().focus()
      return
    }

    // Checking if the solution field is filled in
    if (isEditorContentEmpty(formData.solution)) {
      setShowProblem(false)
      setErrorMessage("Please fill in the Solution field")
      solutionRef.current.getEditor().focus()
      return
    }

    // Checking for profanity
    const fields = ['title', 'problem', 'solution']
    for (const field of fields) {
      if (containsBadWords(formData[field])) {
        setErrorMessage(`Please remove any profanity from the ${field} text`)
        return
      }
    }

    const trimmedProblem = removeExtraTextPBrs(formData.problem)
    const trimmedSolution = removeExtraTextPBrs(formData.solution)

    const response = await onSubmit({
      title: formData.title, 
      problem: trimmedProblem, 
      solution: trimmedSolution, 
      ps_id
    }, authCustomFetch, openModal)

    // console.log(response)
    
    if (response.success) {
      setErrorMessage("")

      closeModalLocally()
    } 
    else setErrorMessage(response.error || response.message)

  }


  return (
    <div className='createproblemsolution modal'>      
      <div className="modal-overlay" onClick={() => closeModalLocally()}></div>

      {loading? <Spinner /> :
      <div className="createproblemsolution-container modal-container">
        <header className="createproblemsolution-header">
          {/* Title */}
          <h1>{initialFormData? "Edit" : "Create"} post</h1>

          {/* Close Button */}
          <button aria-label='Close Modal' name='close-modal-button' className="modal-close-button button-small" onClick={closeModalLocally}>
            <i className="fa-solid fa-x"></i>
          </button>
        </header>

        {notFound? <h1 className='createproblemsolution-nothing-found'>Post could not be found</h1> :

        <form action="" className='createproblemsolution-form' onSubmit={handleSubmit}>
          {/* Title */}
          <div className='createproblemsolution-form-title-container createproblemsolution-form-input-container '>
            <label htmlFor='title'>Title</label>

            <input
            ref={titleRef}
            name="title"
            className='createproblemsolution-form-title'
            value={formData.title}
            onChange={titleHandler}
            type="text" 
            placeholder='Give us a summary...'
            />

            {/* Character Counter */}
            <div className='createproblemsolution-form-title-counter'>
              <span className='createproblemsolution-error-text'>{errorMessage}</span>
              <p><span>{formData.title.length}</span>/<span>{maxTitleCharacters}</span></p>
            </div>

          </div>


          <div className='createproblemsolution-form-display-buttons'>
            <button aria-label='Display Problem' name='display-problem-button' type='button' className={`createproblemsolution-form-display-button ${showProblem ? 'toggled' : ''}`} onClick={() => setShowProblem(true)}>Problem</button>
            <button aria-label='Display Solution' name='display-solution-button' type='button' className={`createproblemsolution-form-display-button ${showProblem ? '' : 'toggled'}`} onClick={() => setShowProblem(false)}>Solution</button>
          </div>

          {/* Problem */}
          <div className={`createproblemsolution-form-problem-container createproblemsolution-form-input-container ${!showProblem? "hidden": ""}`}> {/* ${isOnLastTab? "hidden": ""} */}
            {/* <label htmlFor='problem' style={{display: 'hidden'}}>Problem</label> */}

            <RichTextWithToolbar
            name='problem'
            placeholderText='What was the problem..?'
            className='createproblemsolution-form-problem'
            text={formData.problem}
            setText={problemHandler}  
            ref={problemRef}
            hasToolbar={true}
            />            
          </div>

          {/* Solution */}
          <div className={`createproblemsolution-form-solution-container createproblemsolution-form-input-container ${!showProblem? "": "hidden"}`}>  {/* ${isOnLastTab? "hidden": ""} */}
            {/* <label htmlFor='solution' style={{display: 'hidden'}}>Solution</label> */}

            <RichTextWithToolbar
            name='solution'
            placeholderText='How did you solve it..?'
            className='createproblemsolution-form-solution'
            text={formData.solution}
            setText={solutionHandler}
            ref={solutionRef}
            hasToolbar={true}
            />
          </div>

          {/* Character Counter */}
          <div className='createproblemsolution-form-text-counter'>
            <p><span>{getTextCount(showProblem? formData.problem : formData.solution)}</span>/<span>{maxTextCharacters}</span></p>
          </div>

          {/* Buttons */}
          <div className='createproblemsolution-form-buttons'>

            <button aria-label='Cancel' name='cancel-button' type='button' className='btn button-small' onClick={() => closeModal(navigate)}>Cancel</button>
            <button aria-label='Submit' name='submit-button' type='submit' className='btn button-small'>Submit</button>

            {/* {!isOnLastTab?
              <button type='button' className='btn button-small' onClick={() => closeModal(navigate)}>Cancel</button>
              :
              <button type='button' className='btn button-small' onClick={() => setIsOnLastTab(false)}>Back</button>
            } */}

            {/* {!isOnLastTab?
              <button type='button' className='btn button-small' onClick={handleNext}>Next</button>
              :
              <button type='submit' className='btn button-small'>Submit</button>
            } */}
          </div>
        </form>}
      </div>}
    </div>
  )
}

export default CreateProblemSolution

{/* <textarea 
name="problem" 
className='createproblemsolution-form-problem' 
value={formData.problem} onChange={changeHandler} 
type="text" 
placeholder='What was the problem?' 
required
/> */}

{/* <textarea 
name="solution" 
className='createproblemsolution-form-solution'
value={formData.solution} onChange={changeHandler} 
type="text" 
placeholder='How did you solve it?' 
required
/> */}