import React from 'react'
// import { FacebookShareButton, TwitterShareButton, RedditShareButton, FacebookIcon, TwitterIcon, RedditIcon } from 'react-share'

const ShareButton = ({url, title}) => {

    const handleShare = async () => {
        // console.log(url, title)
        
        const postUrl = `${url}` // Construct the URL using the post ID
        const shareData = {
            title: title, // Optional: Title of the post
            text: 'Check out this post!', // Optional: Text to share along with the URL
            url: postUrl,
        }
    
        try {
            await navigator.share(shareData)
            // console.log('Post shared successfully!')
        } catch (err) {
            // console.error('Error sharing the post') // , err
        }
    }

    return (
        <button aria-label='Share' name='share-button' className='share-button button-small button-with-icon' onClick={handleShare}>
            <span className='share-button-text'>Share</span> <i className="fa-solid fa-share"></i>
        </button>

    // <div className='share-button'>
    //   <FacebookShareButton url={url}>
    //     <FacebookIcon size={32} round />
    //   </FacebookShareButton>

    //   <TwitterShareButton url={url}>
    //     <TwitterIcon size={32} round />
    //   </TwitterShareButton>

    //   <RedditShareButton url={url}>
    //     <RedditIcon size={32} round />
    //   </RedditShareButton>
    // </div>
  )
}

export default ShareButton