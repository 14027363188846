import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import LoginSignupForm from '../../components/loginSignupForm/LoginSignupForm'

const LoginSignup = () => {

    // const { login, signup, requestPasswordReset } = useAuth()
    const { closeModal } = useAuth()

    const [title, setTitle] = useState("Login")
    // const [errorMessage, setErrorMessage] = useState("")

    // const [formData, setFormData] = useState({
    //     username: "",
    //     password: "",
    //     email: "",
    // })
    
    // // Handles Login/Signup submission
    // const handleSubmit = async (e) => {
    //     e.preventDefault()

    //     // Sends data to AuthContext
    //     let response
    //     if (state === "Login") {
    //         response = await login(formData.email, formData.password)
    //     }
    //     else if (state === "Sign Up") {
    //         response = await signup(formData.username, formData.email, formData.password)
    //     }
    //     else if (state === "Reset Password") {
    //         response = await requestPasswordReset(formData.email)
    //     }
        
        
    //     // If an error is returned, set the error message
    //     console.log(response) 
    //     if (response) setErrorMessage(response)
    //     // else closeModal()
    // }
    
    // // Swaps Modal between Login and Sign Up
    // const handleState = (newState) => {
    //     setState(newState)
    //     setErrorMessage("")
    // }

    // // Records form data
    // const changeHandler = (e) => {
    //     setFormData({...formData, [e.target.name]: e.target.value})
    // }
    
    
    return (  // Displays when "login" is in the url query params
    <div className='loginsignup modal'>
        <div className='modal-overlay' onClick={() => closeModal()}></div>

        <div className={`loginsignup-container modal-container ${title === "Email Verification" ? "email-verification" : ""}`}>
            {/* Header */}
            <header className="loginsignup-header">
                {/* Title */}
                <h1 style={{textAlign: title === "Password Reset Email" || title === "Email Verification" ? "center" : "left"}}>{title}</h1>

                {/* Close Button */}
                <button aria-label='Close Modal' name='close-modal-button' className="modal-close-button button-small" onClick={() => closeModal()}>
                    <i className="fa-solid fa-x"></i>
                </button>
            </header>
            <LoginSignupForm type={"modal"} state={title} changeTitleCallback={setTitle}/>
        </div>
    </div>
    )
}

export default LoginSignup


// {/* Form */}
// <form className={`loginsignup-form${errorMessage && " loginsignup-error"}`} onSubmit={handleSubmit}>

// {/* Username (For registration only) */}
// {state === "Sign Up" && <input name="username" value={formData.username} onChange={changeHandler} type="text" placeholder='Your Name' />}

// {/* Email */}
// <input name="email" value={formData.email} onChange={changeHandler} type="email" placeholder='Email Address' />

// {/* Error Message (If present) */}
// {errorMessage && <p className='loginsignup-error-text'>{errorMessage}</p>}

// {/* Password */}
// {state !== "Reset Password" && <input name="password" value={formData.password} onChange={changeHandler} type="password" placeholder='Password' />}

// <button type='submit'>Continue</button>
// </form>

// {/* Login / Register swapper */}
// <div className="loginsignup-state-swapper">

// {state === "Login" && <p>Need to create an account? <span onClick={() => handleState("Sign Up")}>Click here</span></p>}
// {state === "Login" && <p>Forgot your password? <span onClick={() => handleState("Reset Password")}>Click here</span></p>}

// {state === "Sign Up" && <p>Already have an account? <span onClick={() => handleState("Login")}>Click here</span></p>}

// {state === "Reset Password" && <p>Return to Login <span onClick={() => handleState("Login")}>Click here</span></p>}
// </div>

// {/* Privacy policy checkbox */}
// {/* <div className="loginsignup-agree">
// <input type="checkbox" name='' id='' />
// <p>By continuing, you agree to our <span>Terms of Service</span> and <span>Privacy Policy</span></p>
// </div> */}