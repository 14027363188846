import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'

const ModerationNotice = () => {
    const { currentModal, closeModal } = useAuth()
    
    
    return (  // Displays when "login" is in the url query params
    <div className='moderationnotice modal'>
        <div className='modal-overlay' onClick={() => closeModal()}></div>

        <div className={`moderationnotice-container modal-container`}>
            {/* Header */}
            <header className="moderationnotice-header">
                {/* Title */}
                <h1>Moderation Notice</h1>

                {/* Close Button */}
                <button aria-label='Close Modal' name='close-modal-button' className="modal-close-button button-small" onClick={() => closeModal()}>
                    <i className="fa-solid fa-x"></i>
                </button>
            </header>

            <hr />

            <div className="moderationnotice-content">
                <p><span>Message:</span> {currentModal?.message}</p>

                {currentModal?.reason && <p><span>Reason:</span> {currentModal.reason}</p>}

                {currentModal?.expiration && <p><span>Expires In:</span> {currentModal.expiration}</p>}
            </div>

            <hr />

        </div>
    </div>
    )
}

export default ModerationNotice

