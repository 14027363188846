import React from 'react'

const AdSpace = () => {
  return (
    <div className='adspace'>
      <h1>Advertisement</h1>
    </div>
  )
}

export default AdSpace