import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import LoginSignupForm from '../../components/loginSignupForm/LoginSignupForm'

const LoginSignup = () => {
    const navigate = useNavigate()
    const location = useLocation()
    
    const [title, setTitle] = useState("")

    useEffect(() => {
        if (location.pathname === '/login/reset-password') setTitle("Reset Password")
        else if (location.pathname === '/signup') setTitle("Sign Up")
        else setTitle("Login")
    }, [location.pathname])

    useEffect(() => {
        document.title = title
    }, [title])
    
    return (  // Displays when "login" is in the url query params
    <div className='loginsignup page'>

        <div className={`loginsignup-container modal-container ${title === "Email Verification" ? "email-verification" : ""}`}>
            {/* Header */}
            <header className="loginsignup-header">
                {/* Title */}
                <h1 style={{textAlign: title === "Password Reset Email" || title === "Email Verification" ? "center" : "left"}}>{title}</h1>
            </header>
            <LoginSignupForm state={title} changeTitleCallback={setTitle}/>
        </div>
    </div>
    )
}

export default LoginSignup