import React, { createRef, useEffect, useRef, useState } from 'react'
// import samplePosts from '../../Assets/SamplePosts'

import ProblemSolution from '../../components/problemSolution/ProblemSolution'
// import Comments from '../../components/commentsBox/CommentsBox'
import Spinner from '../../components/spinner/Spinner'
import ScrollButton from '../../components/scrollButton/ScrollButton'
import { Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import AdSpace from '../../components/adSpace/AdSpace'
import { getProblemSolutions } from '../../hooks/problemSolutionHooks'


const Home = () => {
  // The local list of ProblemSolutions
  const location = useLocation()
  const [searchQuery, setSearchQuery] = useState()
  const [problemSolutions, setProblemSolutions] = useState({list: [], totalCount: 0})
  const [nothingFound, setNothingFound] = useState(false)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  
  // For ProblemSolution Selection/Display
  const { isLoggedIn, authCustomFetch, openModal } = useAuth()
  const [selected_ps_id, setSelected_ps_id] = useState()
  const scrollRef = useRef(null)

  const problemSolutionsContainerRef = useRef(null)
  const lastElementRef = useRef(null)
  const problemBoxRefs = useRef(new Map()) // Change later?



  // Initial list loading / Search functionality
  useEffect(() => {

    const queryParams = new URLSearchParams(location.search)
    const newSearchQuery = queryParams.get('query')

    // if (searchQuery !== newSearchQuery) {
      setSearchQuery(newSearchQuery)
      setNothingFound(false)
      setLoading(true)

      // Get Posts
      const fetchProblemSolutions = async () => {
        // console.log("MAKING PROBLEMSOLUTIONS REQUEST")
        let problemSolutions = await getProblemSolutions({
          query: {
            searchBy: newSearchQuery? {text: newSearchQuery} : null,
            page: (1)
            // limit,
            // skip
          },
        },
        authCustomFetch,
        openModal
        ) 
        // console.log(problemSolutions)

        // If failure to fetch
        if (problemSolutions.success === false) {
          setNothingFound(true)
          setLoading(false)
          return
        }
        
        // If success, but no PS were found, get trending
        else if (!problemSolutions?.list?.length > 0) {
          // console.log("NO PROBLEMSOLUTIONS FOUND, GETTING TRENDING")
          problemSolutions = await getProblemSolutions({
            query: {
              searchBy: {trending: true},
              page: (1)
            }
          },
          authCustomFetch,
          openModal
          )
          setNothingFound(true)
          // console.log(problemSolutions)
        }
        
        // console.log(problemSolutions)
        
        // Update the state
        setPage(1)
        setProblemSolutions({
          // ...problemSolutions,
          list: problemSolutions.list, 
          totalCount: problemSolutions.totalCount
        })
        setSelected_ps_id(false)
        setLoading(false)
      }

      fetchProblemSolutions()
    // }
  }, [location.search, isLoggedIn]) // searchQuery,


  // Intersection Observer for ProblemSolution Selection/Display
  useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting && `ProblemSolution-${selected_ps_id}` === entry.target.id) {
              setSelected_ps_id() // Deselect the problemSolution if it's about to go out of view
            }
          })
        },
        {
          root: document.querySelector('.home-page'),
          threshold: 0.1, // If PS is only 10% in view of bottom or top of container, close automatically  
          // rootMargin: '-10px 0px -10px 0px'
        }
      )

      problemBoxRefs.current.forEach((ref) => {
        if (ref.current) observer.observe(ref.current) // May or may not break later
      })
  
      return () => { // Cleanup
        problemBoxRefs.current.forEach((ref) => {
          if (ref.current) observer.unobserve(ref.current) // Ensure ref.current is passed to unobserve
        })
      }
  }, [selected_ps_id])

  useEffect(() => {
    const container = document.querySelector('.home-page')
    // console.log(container)
    // console.log(selected_ps_id)

    if(selected_ps_id && window.innerWidth < 768 && window.innerHeight < 750) {
      if (container) {
        container.style.overflowY = 'hidden';
      }
    } else {
      if (container) {
        container.style.overflowY = 'scroll';
      }
    }
  }, [selected_ps_id])


  // Observer for infinite list scrolling
  useEffect(() => {
    // Ensure the ref is current and the last child exists
    const target = problemSolutionsContainerRef.current ? problemSolutionsContainerRef.current.lastChild : null
    let observer
  
    if (target) {
      observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          fetchMoreProblemSolutions()
        }
      }, {
        rootMargin: '100px',
        threshold: 0.8
      })
  
      observer.observe(target)
    }
  
    return () => {
      if (observer && target) {
        observer.unobserve(target)
      }
    }
  }, [problemSolutions.list?.length, problemSolutionsContainerRef])


  // Adds more 
  const fetchMoreProblemSolutions = async () => {
    const queryParams = new URLSearchParams(location.search)
    const newSearchQuery = queryParams.get('query')

    let searchQuery
    if (nothingFound) searchQuery = {trending: true}
    else searchQuery = newSearchQuery ? { text: newSearchQuery } : null

    const newProblemSolutions = await getProblemSolutions({
      query: {
        searchBy: searchQuery,
        page: (page + 1)
        // limit,
        // skip
      }
    }, authCustomFetch)

    if (newProblemSolutions.list?.length > 0) {
      setProblemSolutions(prevPosts => ({
        list: [...prevPosts.list, ...newProblemSolutions.list],
        totalCount: newProblemSolutions.totalCount
      }))
      setPage(prevPage => prevPage + 1)
    } else {
      // Handle the case where no more data is available
      // console.log("No more problem solutions to load.")
    }
  }

  return (
    <div className={`home-page page ${selected_ps_id? "ps-selected" : ""}`} ref={scrollRef}>


      {loading ? <Spinner /> // Still loading?

      :

      <div className="home-page-title">

        {(problemSolutions.length === 0 || nothingFound) && <h1>Nothing {searchQuery && `related to "${searchQuery}"`} could be found!</h1>}
        
        {/* <hr /> */}
        
        <div className="home-page-result-count-container">
          {nothingFound?
          <p className='home-page-result-count'><span>Found {problemSolutions.totalCount} result{problemSolutions.totalCount === 1? "": "s"} for "Currently Trending"</span></p>
          :
          <p className='home-page-result-count'><span>Found {problemSolutions.totalCount} result{problemSolutions.totalCount === 1? "": "s"} {searchQuery && ` for "${searchQuery}"`}</span></p>
          }

          <div className="beta-badge">BETA</div>
        </div>
      </div>}
        
      {!loading && problemSolutions.list?.length > 0 && <div className={`problemsolution-list-container`} ref={problemSolutionsContainerRef}> {/* ref={lastElementRef} */}

        {/* PROBLEM/SOLUTION PRINTING */}
        {problemSolutions.list.map((post, index) => {
          const ref = createRef()
          problemBoxRefs.current.set(post.id, ref)
          const isLastElement = index === problemSolutions.list.length - 1
          // console.log(isLastElement)

          return (
            <React.Fragment key={post.id}>
              <ProblemSolution
                ref={isLastElement ? lastElementRef : ref}
                selected_ps_id={selected_ps_id || false}

                ps_id={post.id}
                user={post.user}
                date={post.date}

                isSameUser={post.isSameUser}
                isHidden={post.isHidden?.active || false}
                reason={post.isHidden?.reason || ""}

                title={post.title}
                problem={post.problem}
                solution={post.solution}

                likeCount={post.likeCount}
                likedByUser={post.likedByUser}

                commentCount={post.commentCount}
                handleShowDetailsCallback={setSelected_ps_id}
              />
              {/* Insert an AdComponent every 6 spots */}
              {(index + 1) % 6 === 0 && <AdSpace key={`adspace-${index}`} />}
            </React.Fragment>
          )
        })}
      </div>}

      {/* MODAL OUTLET */}
      <Outlet />

      {/* SCROLL-TO-TOP-BUTTON */}
      <ScrollButton hideButton={selected_ps_id} scrollRef={scrollRef} value={200} />
    </div>
  )
}

export default Home
