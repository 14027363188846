import { createRef, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'
import ScrollButton from '../../../components/scrollButton/ScrollButton'
import ProblemSolution from '../../../components/problemSolution/ProblemSolution'
import { getProblemSolutions } from '../../../hooks/problemSolutionHooks'

const ProfileProblemSolutionsTab = () => {
  const { authCustomFetch, isLoggedIn } = useAuth()
  const [selected_ps_id, setSelected_ps_id] = useState(null)
  const [ problemSolutions, setProblemSolutions ] = useState({
    list: [],
    totalCount: 0
  })
  
  const scrollRef = useRef(null)
  const lastElementRef = useRef(null)

  const {username} = useParams()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)

  const fetchProblemSolutions = async () => { // False be default
    // console.log("GETTING ALL USER PROBLEM SOLUTIONS")

    // if (shouldLoad) {
    //   setLoading(true)
    //   setPage(1)
    // }

    const response = await getProblemSolutions({
      query: {
        searchBy: {username: username},
        // page: shouldLoad ? 1 : (page + 1)
      }
    }, authCustomFetch)

    // console.log(response)

    setProblemSolutions(response)
    // else setProblemSolutions(prevResponse => ({...prevResponse, list: [...prevResponse.list, ...response.list]}))

    setLoading(false)
  }

  // Refresh when a tab is changed
  useEffect(() => {
    setLoading(true)
    fetchProblemSolutions()
  }, [isLoggedIn, username]) // category

  
  // Refresh when a modal is closed
  // useEffect(() => {
  //   if (modal === undefined && !loading) fetchProblemSolutions()
  // }, [modal]) 

  // Fetch more problem solutions when scrolling
  useEffect(() => {

    const target = lastElementRef.current
    let observer

    if (target) {
      observer = new IntersectionObserver(entries => {
        if (entries[0]?.isIntersecting) {
          fetchMoreProblemSolutions()
        }
      }, {
        // rootMargin: '100px',
        threshold: 0.8
      })
  
      observer.observe(target)
    }

    return () => {
      if (observer && target) {
        observer.unobserve(target)
      }
    }
  }, [problemSolutions.list?.length])


  const fetchMoreProblemSolutions = async () => {
    // console.log("GETTING PROFILE PROBLEM SOLUTIONS")

    // Getting next batch of posts
    const newProblemSolutions = await getProblemSolutions({
      query: {
        searchBy: {username: username},
        page: (page + 1)
      }
    }, authCustomFetch)

    // Adding new posts to the state
    if (newProblemSolutions.list?.length > 0) {
      setProblemSolutions(prevPosts => ({
        list: [...prevPosts.list, ...newProblemSolutions.list],
        totalCount: newProblemSolutions.totalCount
      }))
      setPage(prevPage => prevPage + 1)
    } 
    else {
      // Handle the case where no more data is available
      // console.log("No more problem solutions to load.")
    }
  }

  return (
    loading ? <Spinner /> :

    !problemSolutions.list?.length > 0 ? <h1 className='nothing-found'>No posts could be found</h1> :

    <div className='profile-category-tab profile-problemsolutions-tab' ref={scrollRef}>

      {problemSolutions?.list.map((problemSolution, index) => {
        const {id, title, date, problem, solution, user, likeCount, likedByUser, commentCount, deleteIn, isSameUser} = problemSolution
        const isLastElement = index === problemSolutions.list?.length - 1

        const content = [
          <ProblemSolution
          ref={isLastElement ? lastElementRef : null}
          containerClass = ".profile-problemsolutions-tab"
          type = "profile"

          key = {id}
          ps_id = {id}

          title = {title}
          problem = {problem}
          solution = {solution}
          user = {user}
          date = {date}

          likeCount={likeCount}
          likedByUser={likedByUser}
          isSameUser={isSameUser}

          commentCount={commentCount}

          // beingDeleted={beingDeleted}
          deleteIn={deleteIn}

          // Null removes view functionality
          selected_ps_id={selected_ps_id}
          handleShowDetailsCallback={setSelected_ps_id}
          />
        ]

        return content

      })}

      <ScrollButton scrollRef={scrollRef} value={200} />
    </div>
  )
}

export default ProfileProblemSolutionsTab

      // {problemSolutions.list?.map((problemSolution, index) => {
      //   const {id, title, date, problem, likeCount, likedByUser, commentCount} = problemSolution
      //   const isLastElement = index === problemSolutions.list.length - 1
      //   const ref = isLastElement ? lastElementRef : undefined

      //   return (
      //     <div key={problemSolution._id} className='profile-problemsolution-container' ref={ref}>
      //       <div className='profile-problemsolution-title'> {/* MAKE BOTH A LINK (Post/Profile page) */}
      //         <h2><Link to={`/problemsolution/${id}`}>{title}</Link></h2>
      //         <p>{date}</p>
      //       </div>

      //       <hr />

      //       <div className="profile-problemsolution-details-container">

      //         <div className="problemsolution-details-problem">
      //           <h4 className='problemsolution-problem-title'>The Problem</h4>
      //           <p className='problemsolution-problem-text'>{problem}</p>
      //         </div>

      //       </div>

      //       <div className="profile-problemsolution-footer">
      //         <div className='problemsolution-stats'>

      //           {/* Like Count + Button */}
      //           <LikeButton id={id} parentType='problemSolution' likeCount={likeCount} likedByUser={likedByUser}/>

      //           {/* Comment Count */}
      //           <div className='problemsolution-comment-count'>
      //               <i className="fa-solid fa-comment fa-lg"></i>
      //               <p>{commentCount || 0}</p>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   )
      // })}