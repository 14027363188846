import { useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

const DropdownNav = () => {
  const location = useLocation()

  // Only show certain links when logged-in
  const { isLoggedIn, logout } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [isAboutOpen, setIsAboutOpen] = useState(false)
  const dropdownNavContentRef = useRef(null)

  const toggleDropdown = () => {
    if (isOpen) {
      setIsOpen(false)
      setIsAboutOpen(false)
    }
    else {
      const dropdownContent = dropdownNavContentRef.current
      if (dropdownContent && !dropdownContent.contains(document.activeElement)) {
        setIsOpen(true)
        dropdownContent.focus()
      }
    }
  }

  return (
    <nav 
      className={`dropdownnav ${isOpen? 'open' : ''}`} 
      onBlur={(e) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
          setIsOpen(false)
          setIsAboutOpen(false)
        }
      }}
    >
      <div className='dropdownnav-container'>
        {/* Open/Close Button */}
        <button aria-label='Toggle Dropdown Navigation' name='dropdown-nav-toggle' className={`dropdownnav-button ${isOpen ? 'toggled' : ''}`} onClick={toggleDropdown}>
            {/* Navigation <i className="fa-solid fa-caret-down"></i> */}
            <i className='fa-solid fa-bars fa-lg'></i>
        </button>

        {/* Dropdown Navigation Menu */}
        <div className={`dropdownnav-content`} ref={dropdownNavContentRef} >
          <div className='dropdownnav-content-container'>

            {/* About Pages */}
            <div className="dropdownnav-navlink-button-container">
              <NavLink to='/about/general' className={({ isActive }) => `dropdownnav-navlink ${isActive ? 'isActive' : ''}`}>About Us</NavLink>
              <button className={`dropdownnav-navlink-sub-list-toggle button-link ${isAboutOpen ? 'toggled' : ''}`} aria-label='About us submenu toggle' onClick={() => setIsAboutOpen(!isAboutOpen)}>Legal <i className='fa-solid fa-caret-down'></i></button>
            </div>
            <ul className={`dropdownnav-navlink-sub-list ${isAboutOpen ? 'open' : ''}`} aria-label='About us links'>

              {/* Other Pages */}
              {/* <li><NavLink to='/legal/privacy-policy' className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'} aria-label='Legal Documents'>Legal</NavLink></li> */}
              
              {/* <li><a href="https://www.termsfeed.com/live/89154019-606a-4f74-88db-4367012d1755" target="_blank" rel="noopener noreferrer" className='dropdownnav-navlink' >Terms & Conditions</a></li>
              <li><a href="https://www.termsfeed.com/live/89154019-606a-4f74-88db-4367012d1755" target="_blank" rel="noopener noreferrer" className='dropdownnav-navlink' >Privacy Policy</a></li> */}
              
              {/* <li><NavLink to='/about/general' className={({ isActive }) => `dropdownnav-navlink ${isActive ? 'isActive' : ''}`}>General</NavLink></li> */}
              <li><NavLink to='/about/privacy-policy' className={({ isActive }) => `dropdownnav-navlink ${isActive ? 'isActive' : ''}`}>Privacy Policy</NavLink></li>
              <li><NavLink to='/about/terms-of-service' className={({ isActive }) => `dropdownnav-navlink ${isActive ? 'isActive' : ''}`}>Terms & Conditions</NavLink></li>
              <li><NavLink to='/about/cookie-policy' className={({ isActive }) => `dropdownnav-navlink ${isActive ? 'isActive' : ''}`}>Cookie Policy</NavLink></li>
            </ul>
            

            {/* Post Related */}
            <ul className='dropdownnav-navlink-list' aria-label='Main links'>
              
              {/* <li>
                <NavLink 
                  to='/'
                  className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                  >Browse Posts
                </NavLink>
              </li> */}

              <li>
                <NavLink to={isLoggedIn ? '/createproblemsolution' : '/login'} 
                className={({ isActive }) => isActive && location.pathname !== '/login' ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                >Create a Post
                </NavLink>
              </li>

              <li>
                <NavLink to={'/contact'} 
                className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                >Contact Us
                </NavLink>
              </li>

              <li>
                <NavLink to={'/settings'} 
                className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                >Settings
                </NavLink>
              </li>
            </ul>


            {/* <hr /> <hr className={!isLoggedIn ? 'show-in-tablet-view' : ''}/> */}            

            
            <hr /> {/* <hr className={!isLoggedIn ? 'show-in-tablet-view' : ''}/> */}
            <ul className='dropdownnav-navlink-list' aria-label='Account links'>

              <li> {/* className='show-in-tablet-view' */}
                <NavLink to={isLoggedIn ? '/account/general' : '/login'} 
                className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}
                >{isLoggedIn ? 'My Account' : 'Login'}</NavLink>
              </li>

              {isLoggedIn && 
                <li>
                  <NavLink to='/logout' className={({ isActive }) => isActive ? 'dropdownnav-navlink isActive' : 'dropdownnav-navlink'}>Logout</NavLink>
                </li>
              }
            </ul>

          </div>
        </div>
      </div>
    </nav>
  )
}

export default DropdownNav
