import React, { useRef, useState } from 'react'
import RichTextWithToolbar from '../../components/richTextWithToolbar/RichTextWithToolbar'
import { sendContactForm } from '../../hooks/contactHooks'
import { useAuth } from '../../context/AuthContext'

const Contact = () => {
    const { authCustomFetch, openModal } = useAuth()
    const [formInput, setFormInput] = useState({
        name: '',
        email: '',
        reason: '',
        // message: ''
    })
    const [formMessage, setFormMessage] = useState('')

    const [errorMessage, setErrorMessage] = useState(false)
    const messageRef = useRef(null)


    const handleReset = () => {
        setFormInput({
            name: '',
            email: '',
            reason: '',
        })
        setFormMessage('')
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormInput({ ...formInput, [name]: value })
    }
    const handleMessageChange = (e) => {
        setFormMessage(e)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log(formInput)
        

        const {name, email, reason} = formInput
        const message = formMessage

        if (!name || !email || !reason || !message) {
            setErrorMessage("Please fill out all fields")
            return
        }

        const response = await sendContactForm({name, email, reason, message}, authCustomFetch)

        if (response.success) {
            setErrorMessage(false)

            handleReset()

            openModal({
                name: 'contactSuccess'
            })
        }
        else {
            setErrorMessage(response.message || response.error || 'An error occurred')
        }
    }
    
    return (
        <div className='contact-page page'>

            <div className="contact-container">
                {/* HEADER */}
                <header className='contact-header'>
                <h2 className='contact-title'>Contact Us</h2>
                </header>

                {/* CONTENT */}
                <section className='contact-content'>
                    <form action="" className='contact-form' onSubmit={handleSubmit}>

                        <div className="contact-form-group">

                            {/* NAME */}
                            <div className="contact-form-group-wrapper">
                                <label htmlFor="name" className='text-secondary'>Name</label>
                                <input type="text" name='name' value={formInput.name} onChange={handleChange} aria-label='Enter name' />
                            </div>

                            {/* EMAIL */}
                            <div className="contact-form-group-wrapper">
                                <label htmlFor="email" className='text-secondary'>Email</label>
                                <input type="text" name='email' value={formInput.email} onChange={handleChange} aria-label='Enter email' />
                            </div>

                        </div>

                        {/* <label htmlFor="reason" className='text-secondary'>Reason</label>
                        <input type="text" placeholder='Reason' name='reason' value={formInput.reason} onChange={handleChange} /> */}                        

                        {/* REASON */}
                        <div className="contact-form-group-wrapper">
                            <label htmlFor="reason" className='text-secondary'>Reason</label>
                            <select name="reason" id="contact-reason" value={formInput.reason} onChange={handleChange} aria-label='Select reason for contact'>
                                <option value="">Select Reason</option>
                                <option value="General Inquiry">General Inquiry</option>
                                <option value="Report a Bug">Report a Bug</option>
                                <option value="Feature Request">Feature Request</option>
                                <option value="Account Issues">Account Issues</option>
                                <option value="Content Moderation">Content Moderation</option>
                                <option value="Advertising Inquiry">Advertising Inquiry</option>
                                <option value="Privacy Concern">Privacy Concern</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        {/* <label htmlFor="message" className='text-secondary'>Message</label>
                        <input type="text" placeholder='Message' name='message' value={formInput.message} onChange={handleChange} /> */}

                        {/* MESSAGE */}
                        <div className="contact-form-group-wrapper">
                            <label htmlFor="contact-message" className='text-secondary'>Message</label>
                            <RichTextWithToolbar
                            name='contact-message'
                            id='contact-message'
                            // placeholderText='Message for contact...'
                            className='contactform-text'
                            text={formMessage}
                            setText={handleMessageChange}
                            ref={messageRef}
                            hasToolbar={true}
                            aria-label='Enter message for contact'
                            />
                        </div>


                        {/* SUBMIT */}
                        <button type='submit' aria-label='Submit contact form'>Submit</button>

                        {/* INDICATOR */}
                        <div className='contact-indicator'>
                            {errorMessage && <p className='error-message text-danger'>{errorMessage}</p>}
                            {/* <p className='character-count'>{getTextCount(formInput.reason)} / {maxCharacters}</p> */}
                        </div>
                    </form>
                </section>

                {/* FOOTER */}
                <footer className='contact-footer'></footer>
                
            </div>
        </div>
    )
}

export default Contact
