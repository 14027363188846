import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from './ThemeContext'

export const AuthContext = createContext(null)
export const useAuth = () => useContext(AuthContext)

/* THIS FILE MANAGES THE STATE OF THE APPLICATION

    This includes:
    - Modal Display
    - Tokens
    - User State
*/

export const AuthContextProvider = (props) => {
    const baseURL = process.env.REACT_APP_BACKEND_URL
    const location = useLocation()
    const {changeTheme} = useTheme()

    const [authToken, setAuthToken] = useState(sessionStorage.getItem('accessToken'))
    const [isLoggedIn, setIsLoggedIn] = useState(null) // authToken ? true : false
    // const [moderationNoticeDetails, setModerationNoticeDetails] = useState()

    // THIS KEEPS THE NAME (used to render the modal) AND ANY RELEVANT INFORMATION FOR IT
    const [currentModal, setCurrentModal] = useState(false) // Modal State

    // Checks login status before certain actions
    const checkLoginStatus = async () => {

      if (!isLoggedIn) openModal({name: "loginSignup"})
      else return true

    }
      
    // Maintains login status between pages
    useEffect(() => {
      const maintainLoginStatus = async () => {
        if (!location.pathname.includes('logout')) {
          const response = await generateAccessToken()
          if (response !== null) logout()
        }
      }

      maintainLoginStatus()
      closeModal()
    }, [location.pathname])

    // Modal Stuff -------------------------------------------------------------------------------------

    const openModal = (props) => {
      setCurrentModal({...props})
    }
    const closeModal = () => {
      setCurrentModal(false)
    }

    // useEffect(() => {
    //   if (moderationNoticeDetails) {
    //     openModal("moderationNotice")
    //   }
    // }, [moderationNoticeDetails])

    // Token Stuff -------------------------------------------------------------------------------------

    // TOKEN STATE REFRESH (For SETTING the sessions access token)
    const updateAuthState = (data) => {
      // console.log("Updating information!") // , data

      // console.log(data)

      // If a token is given, save it. Otherwise remove it
      if (data.accessToken) {
        sessionStorage.setItem('accessToken', data.accessToken)
        if (data.theme) changeTheme(data.theme)
      }
      else {
        sessionStorage.removeItem('accessToken')
        // changeTheme("dark")
      }

      // Update the session state
      setAuthToken(data.accessToken)
      setIsLoggedIn(data.accessToken? true : false)
    }

    // TOKEN GENERATION (For GETTING/refreshing the sessions access token)
    const generateAccessToken = async () => {
      // This should call your backend's refresh token endpoint
      // and update the sessionStorage with the new access token
      let errorMessage = "ERROR WHILE GENERATING ACCESS TOKEN"

      try {
        // Making the request
        const response = await fetch(`${baseURL}/generate_access_token`, {
            method: 'GET',
            credentials: 'include',
        })

        const data = await response.json()

        // Updating the auth state
        if (data.accessToken) {
            updateAuthState({
              accessToken: data.accessToken,
              user: data.user
            })
    
            return null
        }
        
      } catch (error) { errorMessage.concat(": " + error.message) }
      
      // console.error(errorMessage)
      return false
  }
      
  // CUSTOM FETCH CALL FOR AUTHORIZATION REQUIREMENTS
  async function authCustomFetch(url, options = {}) {
    // Inject the Authorization header with the access token
    const accessToken = sessionStorage.getItem('accessToken')
    
    if (accessToken) {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
        }
    }

    let response = await fetch(url, options) // First request

    // If unauthorized, try refreshing the token and retrying the request once
    if (response.status === 403) {
      await generateAccessToken()
      const newAccessToken = sessionStorage.getItem('accessToken')

      if (newAccessToken) { // If a new token was given, re-send the request
          options.headers.Authorization = `Bearer ${newAccessToken}`
          response = await fetch(url, options)
      }
      else { // Otherwise (refresh-token was lost/expired), logout and send user to login/signup
        logout()
        window.location.replace("/?login")
      }
    }

    // if (!response.ok) {console.error("ERROR WHILE FETCHING: " + response.statusText)}

    // console.log(response)
    return response
  }
    
  // User Login Related -------------------------------------------------------------------------------------
  
  // USER SIGNUP
  const signup = async (username, email, password, dateOfBirth) => {
    try {
      const response = await fetch(`${baseURL}/signup`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, email, password, dateOfBirth })
      })
  
      const data = await response.json()
      const {success} = data // , accessToken, user, isEmailVerified, error
      
      if (!success) {
        // console.error("ERROR WHILE SIGNING UP: " + error)
        // return error
      }

      return data

    } catch (error) {
      // console.error("ERROR WHILE LOGGING IN: " + error.message)
      return {success: false, error: error.message}
    }
  }
  
  // USER LOGIN
  const login = async (email, password) => {
    try {
      
      const response = await fetch(`${baseURL}/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ email, password })
      })
  
      const data = await response.json()
      // console.log(data)

      const {accessToken, isEmailVerified, theme="dark"} = data
      // const {success, accessToken, user, isEmailVerified, error} = data
      // console.log(data)
      
      // if (!success) console.error("ERROR WHILE LOGGING IN: " + error)

      // else 
      if (accessToken && isEmailVerified)  updateAuthState({accessToken: accessToken, theme: theme})

      // else if (isEmailVerified === false) return false
      return data

    } catch (error) {
      // console.error("ERROR WHILE LOGGING IN: " + error.message)
      return {success: false, error: error.message}
    }
  }  
      
  // USER LOGOUT
  const logout = async () => {
    try {
      // Call the backend to invalidate the refresh token
      const response = await fetch(`${baseURL}/logout`, {
        method: 'POST',
        credentials: 'include', // Cookie is automatically sent        
      })
      
      // Optionally check response status
      if (!response.ok) throw new Error("Server-side logout failed: " + response.statusText)
    }
    catch (error) {
      // console.error("ERROR WHILE LOGGING OUT: " + error.message)
    }

    updateAuthState({accessToken: null, user: null})
    // navigate("/", { replace: true }); // Redirect to home page or login
  }

  // USER EMAIL VERIFICATION
  const verifyEmail = async (token) => {
    try {
      const response = await fetch(`${baseURL}/verify_email?token=${encodeURIComponent(token)}`, {
        method: 'PUT',
        credentials: 'include',
      })

      const data = await response.json()
      // console.log(data)

      // if (!data.success) console.error("ERROR WHILE VERIFYING EMAIL: " + data.error || data.message)
      const {accessToken, isEmailVerified, theme="dark"} = data
      // const {success, accessToken, user, isEmailVerified, error} = data
      // console.log(data)
      
      // if (!success) console.error("ERROR WHILE LOGGING IN: " + error)

      // else 
      if (accessToken && isEmailVerified)  updateAuthState({accessToken: accessToken, theme: theme})

      // else if (isEmailVerified === false) return false
      return data
      
    } catch (error) {
      return { success: false, error: error.message}
    }
  }


  
  // -------------------------------------------------------------------------------------
  
  const contextValue = {
    checkLoginStatus,
    isLoggedIn,

    openModal,
    closeModal,
    currentModal,

    login,
    signup,
    logout,
    verifyEmail,

    authCustomFetch: (url, options) => authCustomFetch(url, options, generateAccessToken),

    // createReport,

    // requestEmailVerification,
    // verifyEmail,

    // requestPasswordReset,
    // verifyPasswordResetToken,
    // resetPassword,

    // getUserAccount,
    // updateUserSettings,
    // getUserActivity,
    // getUserCommentsAndReplies,

    // createProblemSolution,
    // editProblemSolution,
    // deleteProblemSolution,
    // getProblemSolutions,
    // searchProblemSolutions,

    // toggleLike,
    
    // createComment,
    // getComments,
    
    // createReply,
    // getReplies, 
  }

  return (
  <AuthContext.Provider value={contextValue}>
      {props.children}
  </AuthContext.Provider>
  )
}

  // FOR NON-AUTH LOGIN
  // const login = async () => {
  //   setIsLoggedIn(true)
  //   setAuthToken("token")
  //   // Here you would also save the token locally, e.g., in localStorage
  //   localStorage.setItem('authToken', "token")
  //   window.location.replace("/") // Redirecting to home
  // }
    
  // FOR NON-AUTH LOGOUT
  // const logout = () => {
  //   setIsLoggedIn(false)
  //   setAuthToken(null)
  //   localStorage.removeItem('authToken')
  //   window.location.replace("/") // Redirecting to home
  // }