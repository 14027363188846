import React, { useEffect, useState, useCallback } from 'react'

const ScrollButton = ({ hideButton, scrollRef, value }) => {

    const [showScrollButton, setShowScrollButton] = useState(false)

    // Scroll-To-Top-Button listener
    const handleListen = useCallback(() => {
        if (!scrollRef.current) {
            setShowScrollButton(false)
            return
        }

        const withinBoundary = scrollRef.current.scrollTop > value

        if (window.innerWidth <= 767) setShowScrollButton(withinBoundary && !hideButton) // 599
        else setShowScrollButton(withinBoundary)

    }, [hideButton, scrollRef, value])

    useEffect(() => {
        const div = scrollRef.current

        div?.addEventListener('scroll', handleListen)

        return () => div?.removeEventListener('scroll', handleListen)

    }, [handleListen])

    return (

        <button 
            aria-label='Scroll to Top'
            name='scroll-to-top-button'
            className={`scroll-to-top-button ${showScrollButton? "show" : "hide"}`}
            onClick={() => scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' })}
        >
            <i className="fa-solid fa-arrow-up fa-lg"></i>
        </button>
    )
}

export default ScrollButton
