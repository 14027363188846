import React from 'react'
import LikeButton from '../../../components/likeButton/LikeButton'
import { Link } from 'react-router-dom'

const ProfileCommentsTab = ({commentsAndReplies}) => {

    return (
        <div className='profile-category-tab profile-comments-tab'>

        {/* Iterating the Comment/Reply data to create a list */}
        {commentsAndReplies?.map(comment => {
            const {_id, date, type, problemsolution_id, problemsolution_title, text, likeCount, likedByUser, commentCount, replyToUsername} = comment

            return (
              <div key={comment._id} className='profile-comment'>
                <div className='profile-comment-title'> {/* MAKE BOTH A LINK (Post/Profile page) */}
                    <h3><Link to={`/?modal=viewproblemsolution-${problemsolution_id}`} aria-label='View Post'>{problemsolution_title}</Link></h3>
                </div>

                <hr />

                { type === "comment" ? 
                <p className="profile-comment-date">Commented on {date}</p> :
                <p className="profile-comment-date">Replied to <Link to={`/?modal=viewprofile-${replyToUsername}`} aria-label='View Profile'>{replyToUsername}</Link> on {date}</p>
                }

                <div className='profile-comment-details'>
                    <p className='profile-comment-text'>{text}</p>

                </div>

                <hr />

                <div className="profile-comment-footer">
                  <div className='profile-comment-stats'>

                    {/* Like Count + Button */}
                    <LikeButton id={_id} parentType='comment' likeCount={likeCount} likedByUser={likedByUser}/>

                    {/* Comment Count */}
                    {type === "comment" && (
                        <div className='profile-comment-count'>
                            <i className="fa-solid fa-comment fa-lg"></i>
                            <p>{commentCount || 0}</p>
                        </div>
                    )}

                  </div>
                </div>
              </div>
            )
          })}    
        </div>
    )
}

export default ProfileCommentsTab
