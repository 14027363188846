import React from 'react'
import { useAuth } from '../../context/AuthContext'

const ContactSuccess = () => {
    const { currentModal, closeModal } = useAuth()
    
    
    return (  // Displays when "login" is in the url query params
    <div className='contactsuccess modal'>
        <div className='modal-overlay' onClick={() => closeModal()}></div>

        <div className={`contactsuccess-container modal-container`}>
            {/* Header */}
            <header className="contactsuccess-header">
                {/* Title */}
                <h1 style={{textAlign: 'center'}}>Contact Email Sent</h1>

                {/* Close Button */}
                <button aria-label='Close Modal' name='close-modal-button' className="modal-close-button button-small" onClick={() => closeModal()}>
                    <i className="fa-solid fa-x"></i>
                </button>
            </header>

            <hr />

            <div className="contactsuccess-content">
                <p>Your message has been sent!</p>
                <p>We appreciate you reaching out; our support team will respond as soon as possible.</p>
            </div>

            <hr />

        </div>
    </div>
    )
}

export default ContactSuccess
