import { useEffect, useState, forwardRef, useRef } from 'react'
import Comment from '../comment/Comment'
import { useAuth } from '../../context/AuthContext'
import Spinner from '../spinner/Spinner'
import { containsBadWords, limitTextLength, adjustTextAreaHeight, getTextCount, isEditorContentEmpty, removeExtraTextPBrs } from '../../helpers/helper'
import { createComment, getComments } from '../../hooks/commentHooks'
import RichTextWithToolbar from '../richTextWithToolbar/RichTextWithToolbar'

const CommentsBox = forwardRef(({ps_id, showDetails, showComments, isInsideDetails, updateCommentCallback}, ref) => { // Passing the PS ID through
  const [comments, setComments] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { checkLoginStatus, authCustomFetch, currentModal, openModal } = useAuth()
  const [prevModalWasEditComment, setPrevModalWasEditComment] = useState(false)
  
  const [formData, setFormData] = useState({
    text: "",
    replyToUser: ""
  })
  const commentRef = useRef()

  // Function to load comments for the selected PS
  const handleGetComments = async () => {
    // console.log("GETTING COMMENTS")
    setLoading(true)

    const response = await getComments({
      searchBy: { ps_id: ps_id }
    }, authCustomFetch)

    setComments(response)
    setLoading(false)
  }

  // Loads comments when first opened, but does not update after (Unless editing)
  useEffect(() => {
    if (
      // showDetails
      showDetails &&
      ((showComments && !isInsideDetails) || // If comments are shown, and AREN'T inside the details (Desktop View)
      (isInsideDetails)) // If details are shown, and ARE inside the details (Mobile View)
    ) {
      handleGetComments()
    }

  }, [showComments, showDetails])

  // For updating comments after editing
  useEffect(() => {
    // console.log("currentModal", currentModal)
    if (currentModal.name === "editComment") setPrevModalWasEditComment(true)
    else if (!currentModal && prevModalWasEditComment && showDetails) handleGetComments()
    else setPrevModalWasEditComment(false)
  }, [currentModal])


  const maxTextCharacters = 1000 // Max characters allowed for a comment (NEED AN INDICATOR FOR THIS)

  // Records form/comment data
  const changeHandler = (e) => {
    const text = e
    if (getTextCount(text) <= maxTextCharacters) setFormData({ ...formData, text: text })
    // setFormData({ ...formData, text: limitTextLength(text, maxTextCharacters) })

    // const { name, value } = e.target // (For TextArea, not React-Quill)
    // setFormData({ ...formData, [name]: limitTextLength(value, maxTextCharacters) })
    // adjustTextAreaHeight(e.target)
  }

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!checkLoginStatus()) return

    if (containsBadWords(formData.text)) {
      setErrorMessage("Please remove any profanity from the Comment")
      return
    }

    // if (formData.text.trim() === "") {
    //   setErrorMessage("Comments cannot be empty")
    //   return
    // }

    if (isEditorContentEmpty(formData.text)) {
      setErrorMessage("Comments cannot be empty")
      commentRef.current.getEditor().focus()
      return
    }

    const trimmedComment = removeExtraTextPBrs(formData.text)

    const response = await createComment({
      text: trimmedComment, 
      ps_id: ps_id,
    }, 
    authCustomFetch,
    openModal)
    
    if (response.success) {
      // Refresh comments
      handleGetComments() 

      // Update comment count
      updateCommentCallback(prev => prev + 1)

      // Reset form data
      setFormData({ 
        text: "",
        replyToUser: ""
      })
      setErrorMessage("")
      // navigate("/")
    } 
    else setErrorMessage(response.error || response.message)
  }

  return (
    <div ref={ref} className={`commentsbox-container ${showComments || isInsideDetails ? 'show-comments' : 'hide-comments'} ${isInsideDetails ? 'inside-details' : 'outside-details'}`}>
      <div className="commentsbox-wrapper">

        {/* Comments Header */}
        <div className='commentsbox-header'>
          <h3>Comments</h3>
          <hr />
        </div>

        {/* Comment Writing */}
        <form action="" className='comment-form' onSubmit={handleSubmit}>

          <RichTextWithToolbar
            name='comment-text'
            placeholderText='Add a comment...'
            className='comment-form-text'
            text={formData.text}
            setText={changeHandler}
            ref={commentRef}
          />

          {/* <textarea 
            name="text"
            onChange={changeHandler}
            type="text" 
            placeholder='Add a comment...'
            value={formData.text}
            // required
          /> */}

          <div className='comment-form-indicator'>
              <p className='error-message text-danger'>{errorMessage}</p>
              <p className='character-count'>{getTextCount(formData.text)} / {maxTextCharacters}</p>
              {/* <p className='character-count'>{formData.text.length} / {maxTextCharacters}</p> */}
          </div>

          <div className='comment-form-buttons'>
            <button aria-label='Submit Comment' type="submit" className='button-small'>Submit</button>
          </div>
        </form>

        {/* Comment Printing */}
        <div className="commentsbox-list">
          {ps_id && comments.length > 0?
          comments.map(comment => (
            <Comment 
              key={comment._id}
              id={comment._id}
              ps_id={comment.ps_id}

              user={comment.user}
              date={comment.date}

              isSameUser={comment.isSameUser}
              isHidden={comment.isHidden}
              text={comment.text}

              replyCount={comment.replyCount}

              likeCount={comment.likeCount}
              likedByUser={comment.likedByUser}

              updateCommentCallback={updateCommentCallback}
            />
          )) 

          :

          loading?
          <Spinner /> : // Show spinner while loading
          <h4>Nothing yet...</h4> // Message if no comments
          }
        </div>
        
      </div>
    </div>
  )
})

export default CommentsBox
