import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import Spinner from '../../../components/spinner/Spinner'
import { getUserAccount, updateUserSettings } from '../../../hooks/userHooks'
import { requestPasswordReset } from '../../../hooks/passwordHooks'
import { useTheme } from '../../../context/ThemeContext'



const AccountGeneralTab = () => {    
    const { authCustomFetch, openModal, currentModal } = useAuth() //viewProfile
    const { theme, changeTheme } = useTheme()
    // const [theme, changeTheme] = useState('dark')
    const [passwordError, setPasswordError] = useState(false)

    const [user, setUser] = useState({
        account: {
            username: "",
            email: "",
            isEmailVerified: false,
            _id: ""
        },
        settings: {
            theme: theme // "dark"
        },
        activity: {
            problemSolutionCount: 0,
            commentCount: 0,
            // replyCount: "Needs to be implemented",
            likesGiven: 0,
            likesReceived: "Needs to be implemented",
            date_created: "N/A"
        }
    })

    // const [settingsChanged, setSettingsChanged] = useState(false)
    // const [newSettings, setNewSettings] = useState(false)

    const [requestedPasswordReset, setRequestedPasswordReset] = useState(false)
    // const [requestedEmailVerification, setRequestedEmailVerification] = useState(false)

    const [loading, setLoading] = useState(true)

    // Fetch user account data
    const getAccount = async () => {
        // console.log("GETTING 'GENERAL' ACCOUNT DATA")
        const response = await getUserAccount(authCustomFetch)
        // console.log(response)
        
        if (response.account) {
            setUser(prevUser => ({
                ...prevUser,
                account: { ...prevUser.account, ...response.account },
                activity: { ...prevUser.activity, ...response.activity }
            }))
    
            // setSettingsChanged(false)
            // setNewSettings(response.settings)
        }
        // else console.error("Error fetching user account!")
        
        setLoading(false)
    }

    // Initial fetch of user profile
    useEffect(() => {
        if (!currentModal) getAccount()
    }, [currentModal])

    // const changeHandler = (e) => {
    //     if (!settingsChanged) setSettingsChanged(true)

    //     setNewSettings(prevSettings => ({
    //         ...prevSettings,
    //         [e.target.name]: e.target.value
    //     }))
    // }

    // // Settings SAVE
    // const saveHandler = async (e) => {
    //     e.preventDefault()

    //     // Updating local settings
    //     changeTheme(newSettings.theme)

    //     // If settings are the same, do not update
    //     // if (user.settings === newSettings) {
    //     //     setSettingsChanged(false)
    //     //     return
    //     // }

    //     // Updating server settings
    //     const response = await updateUserSettings(newSettings, authCustomFetch)

    //     if (response)  {
    //         setSettingsChanged(false)
    //         getAccount()
    //     }
    // }

    // // Settings REVERT
    // const revertHandler = () => {
    //     setNewSettings(prevSettings => ({ ...prevSettings, ...user.settings }))
    //     setSettingsChanged(false)
    // }

    // Request Password Reset
    const handlePasswordReset = async () => {
        setPasswordError(false)
        setRequestedPasswordReset(false)

        const response = await requestPasswordReset(user.account.email)
        console.log(response)

        if (!response.success) setPasswordError(response.error || response.message)
        setRequestedPasswordReset(true)
    }

    const handleEditUsername = () => {
        openModal({
            name: 'editUsername', 
            username: user.account.username,
            user_id: user.account._id
        })
    }

    // Request Email Verification
    // const handleEmailVerification = async () => {
    //     setRequestedEmailVerification(true)
    //     const response = await requestEmailVerification()
    //     console.log(response)
    // }

    return loading ? <Spinner /> : (

        <div className="account-general-tab account-tab-container">
            {/* <h1 className="account-tab-title">General</h1> */}

            {/* USER INFORMATION */}
            <section className="account-information">

                <h4 className="account-information-title">Personal Information</h4>

                <hr />

                <ul>
                    <li><span>Username:</span> <button aria-label='Edit Username' name='edit-username-button' onClick={handleEditUsername} className='button-link'>{user.account.username}</button></li>
                    <li><span>Email:</span> {user.account.email}</li>
                    <li><span>Registered On:</span> <span>{user.activity.date_created}</span></li>

                    {/* Password Reset */}
                    <li>
                        <button aria-label='Change Password' name='change-password-button' onClick={handlePasswordReset} className='button-link'>Change Password</button> 
                        {requestedPasswordReset && (
                            passwordError ? <p className='text-danger'>{passwordError}</p> :
                            <p className='text-info'>An email has been sent to your inbox!</p>
                        )}
                    </li>

                    {/* Email Verification */}
                    {/* {!user.account.isEmailVerified && 
                        <li>
                            <button onClick={handleEmailVerification}>Verify Email</button> 
                            {requestedEmailVerification && <p className='text-warning'>An email has been sent to your inbox!</p>}
                        </li>
                    } */}
                </ul>

                {/* <Link to="/account">Change Password</Link> */}

            </section>

            {/* USER OVERVIEW */}
            <section className="account-overview">

                <h4 className="account-overview-title">Overview</h4>

                <hr />

                <ul>
                    <li><span>Posts Written:</span> <span>{user.activity.problemSolutionCount}</span></li>
                    <li><span>Comments Written:</span> <span>{user.activity.commentCount}</span></li>
                    <li><span>Likes Given:</span> <span>{user.activity.likesGiven}</span></li>
                    {/* <li><span>Likes Received:</span> <span>{user.activity.likesReceived}</span></li> */}
                </ul>

            </section>

            {/* USER SETTINGS */}
            {/* <section className="account-settings">
                <div className="account-setting-title-wrapper">
                    <h4 className="account-settings-title">Settings</h4>
                    {settingsChanged && <p className='text-danger'>You have unsaved changes!</p>}
                </div>
                <hr />
                <form className="account-settings-form" onSubmit={saveHandler}>
                    <label htmlFor="account-settings-theme-select">Theme Preference:</label>
                    <select id="account-settings-theme-select" name='theme' onChange={changeHandler} value={newSettings.theme} >
                        <option value="dark">Dark</option>
                        <option value="light">Light</option>
                    </select>
                    <div className="account-settings-button-wrapper">
                        <button type="submit" className="account-settings-save-button button-small" disabled={!settingsChanged}>Save Changes</button>
                        <button type="button" className="account-settings-revert-button button-small" disabled={!settingsChanged} onClick={revertHandler}>Revert Changes</button>
                    </div>
                </form>
            </section> */}
        </div>
    )
}

export default AccountGeneralTab


// const { getUserProfile, getProblemSolutions, getUserCommentsAndReplies } = useAuth() //viewProfile
//     // const navigate = useNavigate()

//     useEffect(() => {
//       console.log("GETTING 'GENERAL' ACCOUNT DATA")

//       const fetchData = async (fetchCall, param) => {
//         const response = await fetchCall(param)
//         console.log(response)

//         setuser(response)
//         setLoading(false)
//       }

//       // Data is fetched based on the selected tab
//       if (selectedTab === 'general' || !username) fetchData(getUserProfile, username) // GET call
//       else if (selectedTab === 'problemsolutions') fetchData(getProblemSolutions, {username: username}) // POST call
//       else if (selectedTab === 'comments') fetchData(getUserCommentsAndReplies, username) // GET call
//       else console.error("Error in profile tab selection!")

//     }, [username, selectedTab]) // Refresh when tab/username is changed