import { useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { adjustTextAreaHeight, getTextCount, isEditorContentEmpty, limitTextLength, removeExtraTextPBrs } from '../../helpers/helper'
import { createReport } from '../../hooks/miscHooks'
import RichTextWithToolbar from '../../components/richTextWithToolbar/RichTextWithToolbar'

const ReportForm = () => {
    const { currentModal, closeModal, authCustomFetch } = useAuth()
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [formInput, setFormInput] = useState({
        reason: "",
        category: ""
    })

    const reportRef = useRef(null)

    const submitReport = async (e) => {
        e.preventDefault()

        // if (containsBadWords(username)) setErrorMessage("Please remove any profanity from your Username")
        
        const {reason, category} = formInput
        const {object_id, type} = currentModal

        if (isEditorContentEmpty(reason)) {
            setErrorMessage("Reason cannot be empty")
            reportRef.current.getEditor().focus()
            return
        }
      
        const trimmedReason = removeExtraTextPBrs(reason)

        const response = await createReport({object_id, type, reason: trimmedReason, category}, authCustomFetch)
        // console.log(response)

        if (response.success) {
            setSubmitSuccess(true)
            setErrorMessage(false)
        } 
        else {
            setErrorMessage(response?.message || "An error occurred while creating the report")
        }
    }

    const maxCharacters = 1000
    const handleInputChange = (e) => {
        if (e.target) {
            setFormInput({...formInput, [e.target.name]: limitTextLength(e.target.value, maxCharacters)})
        }
        else {
            const text = e
            if (getTextCount(text) <= maxCharacters) setFormInput({ ...formInput, reason: text })
        }

        // adjustTextAreaHeight(e.target)
    }
    
    
    return (  // Displays when "login" is in the url query params
    <div className={`reportform modal ${submitSuccess ? 'success' : ''}`}>
        <div className='modal-overlay' onClick={() => closeModal()}></div>

        <div className={`reportform-container modal-container`}>
            {/* Header */}
            <header className="reportform-header">
                {/* Title */}
                <h1>Report Form</h1>

                {/* Close Button */}
                <button aria-label='Close Modal' name='close-modal-button' className="modal-close-button button-small" onClick={() => closeModal()}>
                    <i className="fa-solid fa-x"></i>
                </button>
            </header>

            <hr />

            { submitSuccess ? 

                <div className='reportform-content'>
                    <h2 className='text-success'>Submission Successful</h2>
                    <p>Thank you! Our Moderation team will review the details and take appropriate action as soon as possible.</p>
                    <hr />
                </div>
                
                :
                
                <form onSubmit={submitReport} className="reportform-content">

                    <label htmlFor="report-reason" className='text-secondary'>Reason</label>
                    <RichTextWithToolbar
                    name='report-reason'
                    id='report-reason'
                    placeholderText='Reason for report...'
                    className='reportform-text'
                    text={formInput.reason}
                    setText={handleInputChange}
                    ref={reportRef}
                    />

                    {/* <textarea
                    name="reason"
                    placeholder='Reason for report...'
                    value={formInput.reason}
                    onChange={handleInputChange}
                    /> */}
                    
                    <div className='reportform-indicator'>
                        <p className='error-message text-danger'>{errorMessage}</p>
                        <p className='character-count'>{getTextCount(formInput.reason)} / {maxCharacters}</p>
                    </div>

                    <label htmlFor="category" className='text-secondary'>Category</label>
                    <select name="category" id="report-category" value={formInput.category} onChange={handleInputChange}>
                        <option value="">Select Category</option>
                        <option value="Inappropriate">Inappropriate</option>
                        <option value="Harassment">Harassment</option>
                        <option value="Misinformation">Misinformation</option>
                        <option value="Scam">Scam</option>
                        <option value="Spam">Spam</option>
                        <option value="Other">Other</option>
                    </select>

                    <button aria-label='Submit Report' name='submit-button' type='submit'>Submit Report</button>

                </form>
            }

            {/* <hr /> */}

        </div>
    </div>
    )
}

export default ReportForm

