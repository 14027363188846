const baseURL = process.env.REACT_APP_BACKEND_URL

// TOGGLE LIKE
export const toggleLike = async (parent_id, parent_type, authCustomFetch) => {
  try {
    const response = await authCustomFetch(`${baseURL}/toggle_like`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({parent_id, parent_type})
    })

    const data = await response.json()
    // console.log(data)

    // if (!data.success) console.error("ERROR IN TOGGLE LIKE: " + data.error || data.message)

    return data

  } catch (error) {
    return { success: false, error: error.message}
  }
}


// CREATE REPORT (User / Comment / Post)
export const createReport = async ({object_id, type, reason, category}, authCustomFetch) => {
  try {
    const url = `${baseURL}/create_report`
    const response = await authCustomFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({ object_id, type, reason, category })
    })

    const data = await response.json()
    // console.log(data)

    // if (!data.success) console.error("ERROR IN REPORT CREATION: " + data.error || data.message)

    return data

  } catch (error) {
    return { success: false, error: error.message}
  }
}