const baseURL = process.env.REACT_APP_BACKEND_URL

// GET USER ACCOUNT
export const getUserAccount = async (authCustomFetch) => {
  try {
    // Making the request
    const url = `${baseURL}/get_user_account`
    const response = await authCustomFetch(url, { method: 'GET' })

    // Response/Error Messaging
    const data = await response.json()
    // console.log(data)

    // if (!data.success) console.error("ERROR IN USER ACTIVITY RETREIVAL: " + data.error)
  
    return data
      
  } catch (error) {
    // console.error("ERROR IN USER ACTIVITY RETREIVAL: " + error.message)
    return { success: false, error: error.message }
  }
}


// EDIT USERNAME
export const editUsername = async ({newUsername}, authCustomFetch, openModal) => {
  try {
    // console.log(parent_id)
    
    const response = await authCustomFetch(`${baseURL}/edit_username`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({newUsername})
    })

    const data = await response.json()
    // console.log(data)

    // Response/Error Messaging
    if (!data.success) {
      // console.error("ERROR IN USERNAME EDIT: " + (data.message || data.error))

      if (data.isMuted) {
        openModal({name: "moderationNotice", message: data.message, reason: data.reason, expiration: data.expiration})
        // return { success: false, message: "You are currently muted and cannot edit your username." }
      }
    }

    return data

  } catch (error) {
    return { success: false, error: error.message}
  }
}


// UPDATE USER SETTINGS
export const updateUserSettings = async (newSettings, authCustomFetch) => {
  try {
    const url = `${baseURL}/update_user_settings`
    const response = await authCustomFetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({newSettings})
    })

    const data = await response.json()
    // console.log(data)

    // if (!data.success) console.error("ERROR IN UPDATE USER SETTINGS: " + data.error)

    return data

  } catch (error) {
    return { success: false, error: error.message}
  }
}


// GET USER ACTIVITY
export const getUserActivity = async (username, authCustomFetch) => {
  try {
    // Making the request
    const url = `${baseURL}/get_user_activity?username=${encodeURIComponent(username)}`
    const response = await authCustomFetch(url, { method: 'GET' })

    // Response/Error Messaging
    const data = await response.json()
    // console.log(data)

    // if (!data.success) console.error("ERROR IN USER ACTIVITY RETREIVAL: " + data.error)

    return data

  } catch (error) {
    // console.error("ERROR IN USER ACTIVITY RETREIVAL: " + error.message)
    return { success: false, error: error.message}
  }
}

