import React, { useState, useEffect } from 'react'
import { useTheme } from '../../context/ThemeContext'
import { getUserAccount, updateUserSettings } from '../../hooks/userHooks'
import { useAuth } from '../../context/AuthContext'
import Spinner from '../../components/spinner/Spinner'

const Settings = () => {
    const { authCustomFetch, currentModal, isLoggedIn } = useAuth() //viewProfile
    const {theme, changeTheme} = useTheme()
    const [loading, setLoading] = useState(true)

    const [settings, setSettings] = useState({
        theme: theme
    })
    const [settingsChanged, setSettingsChanged] = useState(false)
    const [newSettings, setNewSettings] = useState(false)


    // Fetch user account data
    const getAccount = async () => {
        // console.log("GETTING 'GENERAL' ACCOUNT DATA")
        const response = await getUserAccount(authCustomFetch)
        // console.log(response)
        
        if (response.account) {    
            setSettingsChanged(false)
            setNewSettings(response.settings)
        }
        // else console.error("Error fetching user account!")
        
        setLoading(false)
    }

    // Initial fetch of user profile
    useEffect(() => {
        if (!currentModal) getAccount()
    }, [currentModal])


    // Settings CHANGE
    const changeHandler = (e) => {
        if (!settingsChanged) setSettingsChanged(true)

        setNewSettings(prevSettings => ({
            ...prevSettings,
            [e.target.name]: e.target.value
        }))
    }

    // Settings SAVE
    const saveHandler = async (e) => {
        e.preventDefault()

        // Updating local settings
        changeTheme(newSettings.theme)

        // If settings are the same, do not update
        // if (user.settings === newSettings) {
        //     setSettingsChanged(false)
        //     return
        // }

        // Updating server settings
        let response
        if (isLoggedIn) response = await updateUserSettings(newSettings, authCustomFetch)

        if (response)  {
            setSettingsChanged(false)
            getAccount()
        }
        else {
            setSettingsChanged(false)
        }
    }    

    // Settings REVERT
    const revertHandler = () => {
        setNewSettings(prevSettings => ({ ...prevSettings, ...settings }))
        setSettingsChanged(false)
    }

    return (
    <div className='settings-page page'>
        <div className='settings-container'>

            <header className='settings-header'>
                <h1 className='settings-title'>Settings</h1>
                {settingsChanged && <p className='text-danger'>You have unsaved changes!</p>}

                {/* <hr /> */}

                {/* <nav className='about-tab-links-container'>
                <ul className='about-tab-links-list'>
                    <li><NavLink to="/about/general" aria-label='General' className={({ isActive }) => isActive ? 'about-tab-link isActive link-is-btn' : 'about-tab-link link-is-btn'}>General</NavLink></li>
                    <li><NavLink to="/about/privacy-policy" aria-label='Privacy Policy' className={({ isActive }) => isActive ? 'about-tab-link isActive link-is-btn' : 'about-tab-link link-is-btn'}>Privacy</NavLink></li>
                    <li><NavLink to="/about/terms-of-service" aria-label='Terms of Service' className={({ isActive }) => isActive ? 'about-tab-link isActive link-is-btn' : 'about-tab-link link-is-btn'}>Terms</NavLink></li>
                    <li><NavLink to="/about/cookie-policy" aria-label='Cookie Policy' className={({ isActive }) => isActive ? 'about-tab-link isActive link-is-btn' : 'about-tab-link link-is-btn'}>Cookies</NavLink></li>
                </ul>
                </nav> */}
            </header>
            
            {loading ? <Spinner /> : <div className='settings-content'>

                <form className="settings-form" onSubmit={saveHandler}>

                    {/* THEME SELECT */}
                    <label htmlFor="settings-theme-select">Theme Preference:</label>
                    <select id="settings-theme-select" name='theme' onChange={changeHandler} value={newSettings.theme} >
                        <option value="dark">Dark</option>
                        <option value="light">Light</option>
                    </select>

                    {/* LANGUAGE SELECT */}
                    <label htmlFor="settings-theme-select">Language:</label>
                    <select id="settings-theme-select" name='language' onChange={changeHandler} value={newSettings.language} disabled>
                        <option value="en">English</option>
                    </select>

                    {/* SAVE / RERVERT BUTTONS */}
                    <div className="settings-button-wrapper">
                        <button type="submit" className="settings-save-button button-small" disabled={!settingsChanged}>Save Changes</button>
                        <button type="button" className="settings-revert-button button-small" disabled={!settingsChanged} onClick={revertHandler}>Revert Changes</button>
                    </div>
                </form>
            </div>}

            <footer className='settings-footer' />
        </div>
    </div>
    )
}

export default Settings