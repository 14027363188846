import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { limitTextLength, redirectWithSearch } from '../../helpers/helper'
import { debounce } from 'lodash'


// Non-functional searchbar component
const Searchbar = () => {
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState("")
    const [suggestions, setSuggestions] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const searchbarRef = useRef(null)

    const fetchAutocompleteSuggestions = async (searchText) => {
        if (!searchText.trim()) {
            setSuggestions([])
            return
        }
        const response = await fetch(`http://localhost:4000/get_autocomplete_suggestions?searchTerm=${encodeURIComponent(searchText)}`)
        const data = await response.json()
        setSuggestions(data)

        // console.log(data)
    }

    const debouncedFetchSuggestions = debounce(fetchAutocompleteSuggestions, 300)

    useEffect(() => {
        // console.log(searchTerm)
        debouncedFetchSuggestions(searchTerm)
    }, [searchTerm])

    const handleFocus = () => setShowSuggestions(true) 

    const handleBlur = () => {
        // Delay hiding to check if the new focus is within the component
        setTimeout(() => {
            if (!searchbarRef.current.contains(document.activeElement)) {
                setShowSuggestions(false)
            }
        }, 10)
    }

    const handleSearch = async (event) => {
        event.preventDefault()        
        redirectWithSearch(navigate, searchTerm)
    }
    
    return (
        <form className={`searchbar ${showSuggestions ? 'show-suggestions' : ''}`} ref={searchbarRef} onSubmit={handleSearch}> {/* onSubmit={handleSearch} */}
            <div className="searchbar-input-container">
                <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(limitTextLength(e.target.value, 256))}
                onFocus={handleFocus}
                onBlur={handleBlur}
                />

                <Link to={`/?query=${searchTerm}`} aria-label='Search for posts' title="Search for posts" > {/* &sort_by=relevance */}
                    <i className="fa-solid fa-magnifying-glass store-btns__search fa-lg"></i>
                </Link>
            </div>

            {showSuggestions && suggestions.length > 0 && (
                <div className="searchbar-suggestions" onMouseDown={(e) => e.preventDefault()} onFocus={handleFocus} onBlur={handleBlur}>
                    <div className="searchbar-suggestions-arrow"></div>
                    <div className="searchbar-suggestions-header text-secondary">Suggestions</div>
                    <ul className="searchbar-suggestions-list">
                        {suggestions.map((suggestion, index) => (
                            <li 
                                key={index}
                                className='searchbar-suggestion'
                                onClick={() => {
                                    setSearchTerm(suggestion.title);
                                    setShowSuggestions(false);
                                }}
                            >
                                {suggestion.title}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </form>
    )
}

export default Searchbar
