import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { closeModal } from '../../helpers/helper'
import ShareButton from '../../components/shareButton/ShareButton';

const ViewProfile = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const {username} = useParams()

    return (
      <div className='viewprofile modal'>
        <div className='modal-overlay' onClick={() => closeModal(navigate)}></div>
  
        <div className="viewprofile-container modal-container">
          <header className="viewprofile-header">
              {/* Title */}
              {/* <div className='viewprofile-title'> */}
                <h1>{username}</h1>
              {/* </div> */}

              {/* Share Button */}
              <ShareButton url={`/profile/${username}`} title={username} />

              {/* Close Button */}
              <button aria-label='Close Modal' name='close-modal-button' className="modal-close-button button-small" onClick={() => closeModal(navigate)}>
                <i className="fa-solid fa-x"></i>
              </button>
          </header>

          <section className='viewprofile-main'>
            
            {/* CATEGORY TABS */}
            <div className="viewprofile-nav-button-container">

              {/* General */}
              <NavLink to={"general" + location.search} aria-label='Profile General' className={({isActive}) => isActive ? 'viewprofile-nav-button isActive link-is-btn' : 'viewprofile-nav-button link-is-btn'}>General</NavLink>
              {/* <button 
                className={`viewprofile-nav-button ${selectedTab === 'general' ? 'selected' : ''}`} 
                onClick={() => handleTabChange('general')}>
                General
              </button> */}

              {/* Problem Solutions */}
              <NavLink to={"posts" + location.search} aria-label='Profile Posts' className={({isActive}) => isActive ? 'viewprofile-nav-button isActive link-is-btn' : 'viewprofile-nav-button link-is-btn'}>Posts</NavLink>
              {/* <button 
                // className={`viewprofile-nav-button ${selectedTab === 'problemsolutions' ? 'selected' : ''}`} // {/*  - ({profile?.problemsolutions?.length || 0}) */}
                {/* // onClick={() => handleTabChange('problemsolutions')}> */}
                  {/* ProblemSolutions */}
              {/* </button>  */}

              {/* Comments */}
              {/* <button 
                className={`viewprofile-nav-button ${selectedTab === 'comments' ? 'selected' : ''}`}
                onClick={() => handleTabChange('comments')}>
                  Comments
              </button> */}
            </div>


            {/* CONTENT PRINTING */}            
            <Outlet/>

          </section>                
        </div>
      </div>
    )
}

export default ViewProfile
