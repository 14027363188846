import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { closeModal } from '../../helpers/helper'
import ProblemSolution from '../../components/problemSolution/ProblemSolution'
import Spinner from '../../components/spinner/Spinner'
import { getProblemSolutions } from "../../hooks/problemSolutionHooks"

const ViewProblemSolution = () => {
    const { isLoggedIn, authCustomFetch } = useAuth() //viewProblemSolution
    const navigate = useNavigate()
    const [problemSolution, setProblemSolution] = useState()
    const [loading, setLoading] = useState(true)    
    
    
    // Use the ps_id in the Url Query to fetch the ProblemSolution data
    const {ps_id} = useParams()
    useEffect(() => {
        
        if (ps_id) {
            const fetchProblemSolution = async () => {
                setLoading(true)

                // console.log("MAKING REQUEST")
                const response = await getProblemSolutions({
                    query: {
                        searchBy: {ps_id: ps_id}
                    }
                }, authCustomFetch)
                
                // console.log(response)

                setProblemSolution(response?.list?.length > 0 ? response.list[0] : false)
                setLoading(false)
            }
            fetchProblemSolution()
        }
    }, [ps_id, isLoggedIn]) // Refresh when the Url ID changes

    return (
        <div className='viewproblemsolution modal'>
            <div className='modal-overlay' onClick={() => closeModal(navigate)}></div>
    
            <div className={`viewproblemsolution-container ${!problemSolution && !loading? "no-results" : ""}`}> {/* modal-container */}
                {!loading && !problemSolution && <header className="viewproblemsolution-header">
                    {/* Title */}
                    <h1>{problemSolution? problemSolution.title : "ViewPost"}</h1>
        
                    {/* Close Button */}
                    <button aria-label='Close Modal' name='close-modal-button' className="modal-close-button button-small" onClick={() => closeModal(navigate)}>
                        <i className="fa-solid fa-x"></i>
                    </button>
                </header>}

                {/* PROBLEM/SOLUTION PRINTING */}

                {problemSolution?
                // problemSolution.map((post) => {

                    // return (
                        <ProblemSolution
                        // ref = {ref}
                        containerClass = ""
                        type = "single"

                        key = {problemSolution.id}
                        ps_id = {problemSolution.id}

                        title = {problemSolution.title}
                        problem = {problemSolution.problem}
                        solution = {problemSolution.solution}
                        user = {problemSolution.user}
                        date = {problemSolution.date}

                        likeCount={problemSolution.likeCount}
                        likedByUser={problemSolution.likedByUser}
                        isSameUser={problemSolution.isSameUser}

                        commentCount={problemSolution.commentCount}

                        // beingDeleted={problemSolution.beingDeleted}
                        deleteIn={problemSolution.deleteIn}

                        // Null removes view functionality
                        selected_ps_id={problemSolution.id}
                        handleShowDetailsCallback={problemSolution.id}
                        />
                    // <ProblemSolution

                    // key = {post.id}
                    // id = {post.id}

                    // title = {post.title}
                    // problem = {post.problem}
                    // solution = {post.solution}
                    // user = {post.user}
                    // date = {post.date}

                    // likeCount={post.likeCount}
                    // likedByUser={post.likedByUser}

                    // commentCount={post.commentCount}
                    // />
                    // )
                // }) 
                :

                // If no PS are present...
                loading ?
                    <Spinner /> : // ...because they're still being loaded, show a spinner


                    <h1>Nothing was found!</h1>
                }
            </div>
        </div>
      )
}

export default ViewProblemSolution
