import React, { createContext, useContext, useEffect, useState } from 'react'

const ThemeContext = createContext()

export const useTheme = () => useContext(ThemeContext)

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark') // default theme

    // const toggleTheme = () => {
    //     setTheme(theme === 'light' ? 'dark' : 'light')
    // }

    useEffect(() => {
        const body = document.body
        body.setAttribute('data-theme', theme)
    }, [theme])

    const changeTheme = (newTheme) => {
        // console.log(newTheme)

        const themes = ['light', 'dark']
        if (!themes.includes(newTheme)) return

        setTheme(newTheme)
        localStorage.setItem('theme', newTheme)
    }

    return (
        <ThemeContext.Provider value={{ theme, changeTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}