import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
      <p>&copy; 2024 <Link to='/about' aria-label='About Us' title="About Us">Collaborative Problem Solver</Link> - All Rights Reserved || <Link to='/about/privacy-policy' aria-label='Privacy Policy' title="Privacy Policy">Privacy Policy</Link> || <Link to='/about/terms-of-service' aria-label='Terms of Service' title="Terms of Service">Terms of Service</Link> || <Link to='/about/cookie-policy' aria-label='Cookie Policy' title="Cookie Policy">Cookie Policy</Link></p>
    </div>
  )
}

export default Footer
