const baseURL = process.env.REACT_APP_BACKEND_URL

// CREATE POST
export const createProblemSolution = async ({title, problem, solution}, authCustomFetch, openModal) => {
    try {
        const response = await authCustomFetch(`${baseURL}/create_problemsolution`, {
            method: "POST",
        headers: {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${token}` // This is automatically added by "customFetch"
        },
        body: JSON.stringify({title, problem, solution})
        })

        const data = await response.json()
        // console.log(data)

        // Response/Error Messaging
        if (!data.success) {
        // console.error("ERROR IN PROBLEM SOLUTION CREATION: " + data.message || data.error)

            if (data.isMuted) {
                // setModerationNoticeDetails({message: data.message, reason: data.reason, expiration: data.expiration})
                openModal({name: "moderationNotice", message: data.message, reason: data.reason, expiration: data.expiration})
                return { success: false, message: "You are currently muted and cannot create a ProblemSolution." }
            }
        }

        return data

    } catch (error) {
        return { success: false, error: error.message }
    }
}


//  EDIT POST
export const editProblemSolution = async ({title, problem, solution, ps_id}, authCustomFetch, openModal) => {    
    try {
        const response = await authCustomFetch(`${baseURL}/edit_problemsolution/${ps_id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({newTitle: title, newProblem: problem, newSolution: solution})
        })

        const data = await response.json()
        // console.log(data)

        if (!data.success) {
            // console.error("ERROR IN PROBLEM SOLUTION EDITING: " + data.error || data.message)

            if (data.isMuted) {
                // setModerationNoticeDetails({message: data.message, reason: data.reason, expiration: data.expiration})
                openModal({name: "moderationNotice", message: data.message, reason: data.reason, expiration: data.expiration})
                return { success: false, message: "You are currently muted and cannot edit a ProblemSolution." }
            }
        }

        return data

    } catch (error) {
        return { success: false, error: error.message}
    }
}

// DELETE POST
export const deleteProblemSolution = async (ps_id, authCustomFetch) => {
    try {
        const response = await authCustomFetch(`${baseURL}/delete_problemsolution/${ps_id}`, { method: 'DELETE' })
        const data = await response.json()
        // console.log(data)

        // if (!data.success) console.error("ERROR IN PROBLEM SOLUTION DELETION: " + data.error || data.message)

        return data

    } catch (error) {
        return { success: false, error: error.message}
    }
}

// export const getProblemSolutions = async (searchParams, authCustomFetch, openModal) => { // searchParams
//     const { searchBy, page=1 } = searchParams

//     console.log(searchBy)

//     const category = searchBy?.length > 0 ? Object?.keys(searchBy)[0] : ""
//     const query = searchBy?.length > 0 ? searchBy[category] : ""

//     console.log(category, query, page)

//     try { // May need to add Limit / Skip later !!!
//         const url = `${baseURL}/get_problemsolutions?query=${encodeURIComponent(query)}&category=${encodeURIComponent(category)}&page=${encodeURIComponent(page)}`
//         const options = {method: 'GET'}

//         const response = await authCustomFetch(url, options)

//         const data = await response.json()
//         // console.log(data)

//         return data

//     } catch (error) {
//         // console.error("ERROR IN LIST VIEW DATA RETRIEVAL:", error.message)
//         return false
//     }
// }

// PROBLEMSOLUTION GET
export const getProblemSolutions = async ({query}, authCustomFetch, openModal) => { //, page = 1, limit = 10, skip = false
    try {
        // console.log(query)
        
        const response = await authCustomFetch(`${baseURL}/get_problemsolutions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: query,
                // page: page,
                // limit: limit,
                // skip: skip
            })
        })

        const data = await response.json()
        // console.log(data)
        
        // Response/Error Messaging
        // if (!data.success) console.error("ERROR IN PROBLEM-POST RETREIVAL: " + data.error)

        return data

    } catch (error) {
        return { success: false, error: error.message}
    }
}

