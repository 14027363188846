import { useRef, useState } from 'react'
// import { NavLink, Link, useNavigate } from 'react-router-dom'
// import { useAuth } from '../../context/AuthContext'
import ReportButton from '../reportButton/ReportButton'
import { useAuth } from '../../context/AuthContext'
import { NavLink } from 'react-router-dom'

const DropdownMenu = ({id, type, createdBySelf = false}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownMenuContentRef = useRef(null)
  // const navigate = useNavigate()

  // Only show certain links when logged-in
  // const { isLoggedIn, logout } = useAuth()

  const { isLoggedIn, openModal } = useAuth()

  const toggleDropdown = () => {
    if (isOpen) setIsOpen(false)

    else {
      const dropdownContent = dropdownMenuContentRef.current
      if (dropdownContent && !dropdownContent.contains(document.activeElement)) {
        setIsOpen(true)
        dropdownContent.focus()
      }
    }
  }

  const handleReport = () => {
    openModal(isLoggedIn ? {name: "reportForm", object_id: id, type: type} : {name: "loginSignup"})
  }

  const handleEdit = () => {
    openModal(isLoggedIn ? {name: "editComment", object_id: id, type: type} : {name: "loginSignup"})
  }

  return (
    <nav 
      className={`dropdownmenu ${isOpen? 'open' : ''}`} 
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) setIsOpen(false)
      }}
    >
      <div className='dropdownmenu-container'>

        {/* Open/Close Button */}
        <button aria-label='Toggle Dropdown Menu' className={`dropdownmenu-button button-small ${isOpen ? 'toggled' : ''}`} onClick={toggleDropdown}>
            <i className="fa-solid fa-ellipsis"></i>
        </button>

        {/* Dropdown Navigation menu */}
        <div className={`dropdownmenu-content`} ref={dropdownMenuContentRef}>
            <ul className='dropdownmenu-button-list'>
              
              {/* Edit */}
              {createdBySelf && <li>
                {/* <ReportButton id={id} type={type} /> */}
                { type === 'Comment' ?
                  <button aria-label='Edit' name='edit-button' className='edit-button' onClick={handleEdit}>
                    <span className='edit-button-text'>Edit</span>
                  </button>
                   
                  :

                  type === 'Post' &&
                  <NavLink to={`/account/posts/all/edit/${id}`} className='edit-button' aria-label='Edit' name='edit-button'>
                    Edit
                  </NavLink>
                }
              </li>}

              {/* Report */}
              {!createdBySelf && <li>
                {/* <ReportButton id={id} type={type} /> */}
                <button aria-label='Report' name='report-button' className='report-button' onClick={handleReport}>
                  <span className='report-button-text'>Report</span>
                </button>
              </li>}

              
            </ul>
        </div>
      </div>
    </nav>
  )
}

export default DropdownMenu
