// import { useState } from 'react'
import { forwardRef, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// VIDEO --> https://youtu.be/ahNdQaq0mHg
// Quill Documentation --> https://quilljs.com/docs/guides/cloning-medium-with-parchment#blockquote-and-headers


const RichTextWithToolbar = forwardRef(({ className, name, placeholderText, text, setText, hasToolbar = false }, ref) => {
  // const [value, setValue] = useState('')


  // useEffect(() => {
  //   console.log(text)
  // }, [text])
  
  const modules = {
    toolbar: hasToolbar ? [
      [
        { 'header': '2' } // ['3', 'false']
      ],
      
      [
        'bold', 
        'italic', 
        'underline', 
        'strike', 
        // 'blockquote'
      ],

      [
        { 'list': 'ordered'}, 
        { 'list': 'bullet'},
        
      ],

      // [
      //   { 'line': 'line'},
      //   { 'indent': '-1'},
      //   { 'indent': '+1' },
      //   { 'script': 'sub'}, 
      //   { 'script': 'super' },
      // ],
      
      // [
      //   'link', 
      //   'image'
      // ],

      // [{ 'indent': '-1'}, { 'indent': '+1' }],
      // [{ 'direction': 'rtl' }],
    ] : false
  }

  return (
    <div className='richtext-container'>

          <ReactQuill
          name={name}
          theme='snow'
          placeholder={placeholderText} 
          value={text} 
          onChange={setText}
          className={`richtext-editor-input ${className}`}
          modules={modules}
          ref={ref}
          />

    </div>
  )
})

export default RichTextWithToolbar
