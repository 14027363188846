import { useState, useEffect, useRef, createRef } from 'react'
import Spinner from '../../../components/spinner/Spinner'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
// import LikeButton from '../../../components/likeButton/LikeButton'
import { useAuth } from '../../../context/AuthContext'
import ProblemSolution from '../../../components/problemSolution/ProblemSolution'
import { getProblemSolutions, deleteProblemSolution } from '../../../hooks/problemSolutionHooks'


const AccountPostsTab = () => {
  const location = useLocation()
  const path = location.pathname
  const category = path.split('/')[3]
  const modal = path.split('/')[4]

  const { authCustomFetch } = useAuth()
  const [selected_ps_id, setSelected_ps_id] = useState()
  const [problemSolutions, setProblemSolutions] = useState({
    list: [],
    totalCount: 0
  })
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const lastElementRef = useRef(null)
  const problemBoxRefs = useRef(new Map())

  const fetchProblemSolutions = async (shouldLoad = false) => { // False be default
    const getDeleted = category === 'deleted'
    // console.log(getDeleted ? "GETTING DELETED PROBLEM SOLUTIONS" : "GETTING ALL PROBLEM SOLUTIONS")

    if (shouldLoad) {
      setLoading(true)
      setPage(1)
    }

    const response = await getProblemSolutions({
      query: {
        searchBy: { myAccount: true }, 
        getDeleted: getDeleted,
        // page: shouldLoad ? 1 : (page + 1)
      }
    }, authCustomFetch)
    // console.log(response)

    setProblemSolutions(response)
    // else setProblemSolutions(prevResponse => ({...prevResponse, list: [...prevResponse.list, ...response.list]}))

    setLoading(false)
  }

  // Refresh when a tab is changed
  useEffect(() => {
    setLoading(true)
    fetchProblemSolutions(true) // Loading true
    setSelected_ps_id(false)
  }, [category])

  
  // Refresh when a modal is closed
  useEffect(() => {
    if (modal === undefined && !loading) fetchProblemSolutions()
  }, [modal]) 

  // Fetch more problem solutions when scrolling
  useEffect(() => {
    const target = lastElementRef?.current
    let observer

    if (target) {
      observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) fetchMoreProblemSolutions() // setPage(prevPage => prevPage + 1)
      }, {
        rootMargin: '100px',
        threshold: 0.8
      })
    
      if (target) observer.observe(target)
    }
  
    return () => {
      if (observer && target) {
        observer.unobserve(target)
      }
    }
  }, [problemSolutions.list?.length])

  useEffect(() => {
    const container = document.querySelector('.account-page')
    // console.log(container)
    // console.log(selected_ps_id)

    if(selected_ps_id && window.innerWidth < 768 && window.innerHeight < 750) {
      if (container) {
        container.style.overflowY = 'hidden';
      }
    } else {
      if (container) {
        container.style.overflowY = 'scroll';
      }
    }
  }, [selected_ps_id])

  const fetchMoreProblemSolutions = async () => {
    const getDeleted = category === 'deleted'
    // console.log(getDeleted ? "GETTING MORE DELETED PROBLEM SOLUTIONS" : "GETTING MORE PROBLEM SOLUTIONS")
    const newProblemSolutions = await getProblemSolutions({
      query: {
        searchBy: {myAccount: true},
        getDeleted: getDeleted,
        page: (page + 1)
      }
    }, authCustomFetch)

    if (newProblemSolutions.list.length > 0) {
      setProblemSolutions(prevPosts => ({
        list: [...prevPosts.list, ...newProblemSolutions.list],
        totalCount: newProblemSolutions.totalCount
      }))
      setPage(prevPage => prevPage + 1)
    } else {
      // Handle the case where no more data is available
      // console.log("No more problem solutions to load.")
    }
  }



  const deleteToggleHandler = async (id) => {
    // const response = 
    await deleteProblemSolution(id, authCustomFetch)
    // console.log(response)

    fetchProblemSolutions()
  }


  return (

    <div className='account-category-tab account-posts-tab'>

      <div className='account-posts-categories'>
        <NavLink to={`/account/posts/all`} className={`account-posts-categories-link link-is-btn ${category === 'all' ? 'isActive' : ''}`}>All</NavLink>
        <NavLink to={`/account/posts/deleted`} className={`account-posts-categories-link link-is-btn ${category === 'deleted' ? 'isActive' : ''}`}>Deleted</NavLink>
      </div>

      {/* POST PRINTING */}
      <div className='account-posts-list-container'>
        
        {loading ? <Spinner /> :      
        
          problemSolutions?.list?.map((problemSolution, index) => {
            const {id, title, date, problem, solution, user, likeCount, likedByUser, commentCount, deleteIn} = problemSolution
            // const beingDeleted = deleteIn && deleteIn !== null
            const ref = createRef()
            problemBoxRefs.current.set(id, ref)
            const isLastElement = index === problemSolutions.list.length - 1
            
            const content = [
              <ProblemSolution
              ref = {isLastElement ? lastElementRef : ref}
              containerClass = ".account-page"
              type = "account"
              
              key = {id}
              ps_id = {id}
              
              title = {title}
              problem = {problem}
              solution = {solution}
              user = {user}
              date = {date}
              
              likeCount={likeCount}
              likedByUser={likedByUser}

              commentCount={commentCount}
              
              // beingDeleted={beingDeleted}
              deleteIn={deleteIn}
              deleteToggleHandler={deleteToggleHandler}
              
              // Null removes view functionality
              selected_ps_id={selected_ps_id}
              handleShowDetailsCallback={setSelected_ps_id}
              />
            ]
            
            return content

          })
        }
      </div>

      {problemSolutions?.list?.length === 0 && !loading && <h1 className='nothing-found'>No posts could be found</h1>}

      <Outlet />
    </div>
  )
}

export default AccountPostsTab

// return (
//   <div key={id} className='profile-problemsolution-container'>
//     <div className='profile-problemsolution-title'> {/* MAKE BOTH A LINK (Post/Profile page) */}
//       <h2><Link to={`/problemsolution/${id}`}>{title}</Link></h2>
//       <p>{!beingDeleted ? date : <span className='text-danger'>{formatTimeDifference(deleteIn)}</span>}</p>

//       {/* Edit / Delete / Restore */}
//       <div className='edit-delete-buttons'>
//         {deleteIn === null && 
//           <NavLink to={`/account/posts/all/editproblemsolution/${id}`}>
//             <button className='edit-button button-small button-with-icon'>Edit <i className="fa-solid fa-pen"></i></button>
//           </NavLink>
//         }
        
//         {!beingDeleted ?
//         <button onClick={() => deleteToggleHandler(id)} className='delete-button button-small button-with-icon'>
//           Delete <i className="fa-solid fa-trash"></i>
//         </button> 

//         :

//         <button onClick={() => deleteToggleHandler(id)} className='restore-button button-small button-with-icon'>
//           Restore <i className="fa-solid fa-undo"></i>
//         </button>}

//       </div>
//     </div>

//     <hr />

//     <div className="profile-problemsolution-details-container">

//       <div className="problemsolution-details-problem">
//         <h4 className='problemsolution-problem-title'>The Problem</h4>
//         <p className='problemsolution-problem-text'>{problem}</p>
//       </div>

//     </div>

//     <div className="profile-problemsolution-footer">
//       <div className='problemsolution-stats'>

//         {/* Like Count + Button */}
//         <LikeButton id={id} parentType='problemSolution' likeCount={likeCount} likedByUser={likedByUser}/>

//         {/* Comment Count */}
//         <div className='problemsolution-comment-count'>
//             <i className="fa-solid fa-comment fa-lg"></i>
//             <p>{commentCount || 0}</p>
//         </div>
//       </div>
//     </div>
//   </div>
// )