import { forwardRef, useEffect, useState, useRef } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import LikeButton from '../likeButton/LikeButton'
import CommentsBox from '../commentsBox/CommentsBox'
import { closeModal } from '../../helpers/helper'
import ShareButton from '../shareButton/ShareButton'
import DropdownMenu from '../dropdownMenu/DropdownMenu'
// import { redirectWithParams } from '../../helpers/helper'

const ProblemSolution = forwardRef(({
    containerClass = ".home-page",
    type = "standard", // Determines wether to show Share/Menu or Edit/Delete/Restore buttons
                       // Options are "standard", "account" and "profile"
    ps_id,
    user, 
    title, 
    date,

    isSameUser = false,
    isHidden,
    reason,

    problem, 
    solution,

    likeCount, 
    likedByUser, 
    commentCount, 

    // For showing details
    selected_ps_id,
    handleShowDetailsCallback,

    deleteIn,
    deleteToggleHandler
}, ref) => {

    const location = useLocation()
    const navigate = useNavigate()

    // const navigate = useNavigate()
    const [showDetails, setShowDetails] = useState(Boolean(selected_ps_id === ps_id))
    const [showComments, setShowComments] = useState(false)
    const [localCommentCount, setLocalCommentCount] = useState(commentCount || 0)
    const [isMobile, setIsMobile] = useState()
    const detailsRef = useRef(null)
    const innerCommentsBoxRef = useRef(null)

    // PS Details & CommentBox State
    useEffect(() => {
        setShowDetails(selected_ps_id === ps_id? true : false)
    }, [selected_ps_id])

    useEffect(() => {
        if (!showDetails) {
            setShowComments(false)
            detailsRef.current.scrollTop = 0 // Scroll to the top
        }
    }, [showDetails])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024)
        }
    
        // Set initial state based on current window width
        setIsMobile(window.innerWidth < 1024)
    
        // Add event listener
        window.addEventListener('resize', handleResize)
    
        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const formatTimeDifference = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        return `Removal in ${hours}hr${hours !== 1 ? 's' : ''}` + `${minutes > 0 ? " and " + minutes + "min" : ''}`
    }

    // Scrolls the selected PS to the top of the screen when opened
    const handleShowDetails = () => {
        if (type === "profile") return navigate(`/post/${ps_id}${location.search}`)

        const element = ref?.current
        const container = document.querySelector(containerClass)
        // console.log(container)
        // console.log(ref)

        if (element && container && !showDetails && window.innerWidth < 768) { // && window.innerWidth < 600
            const elementRect = element.getBoundingClientRect()
            const containerRect = container.getBoundingClientRect()
            const scrollTop = elementRect.top - containerRect.top + container.scrollTop - 10

            container.scrollTo({
                top: scrollTop,
                // top: elementRect.top - containerRect.top + container.scrollTop - (container.clientHeight / 2) + (elementRect.height / 2),
                // behavior: 'smooth'
            })

            // Delayed scroll if still selected
            setTimeout(() => {
                console.log("HERE", showDetails)
                // if (showDetails) { // Check if still selected
                    const container = document.querySelector(containerClass)
                    console.log(container)

                    container.style.overflowX = 'auto'
                    
                    container.scrollTo({
                        top: scrollTop, 
                        // behavior: 'smooth'
                    })

                    container.style.overflowX = 'hidden'
                // }
            }, 500)
            
            // const topDistance = elementRect.top - containerRect.top
            // const bottomDistance = containerRect.bottom - elementRect.bottom
            
            // If 10px from the top/bottom of parent, scroll the element fully into view
            // if (topDistance < 10 || bottomDistance < 10) {
                // container.scrollTop = elementRect.top - containerRect.top + container.scrollTop - 10
            // }
        }
        // ${location.search}
        
        setShowDetails(!showDetails)
        if (handleShowDetailsCallback) handleShowDetailsCallback(showDetails? null : ps_id) // comments: comments})
    }

    const handleShowComments = () => {
        if (!showDetails) handleShowDetails()
    
        if (window.innerWidth >= 1024) {
            setShowComments(!showComments)
        } else {
            // Scroll within the problemsolution-details using detailsRef
            if (detailsRef.current && innerCommentsBoxRef.current) {
                const commentsBoxPosition = innerCommentsBoxRef.current.offsetTop
                detailsRef.current.scrollTop = commentsBoxPosition - 20 // Adjust 20px for some padding if needed
            }
        }
    }


  return (
    <div 
    className={`problemsolution ${showDetails ? 'show-details' : 'hide-details'} ${showComments ? 'show-comments' : 'hide-comments'}`}
    id={`ProblemSolution-${ps_id}`}
    ref={ref}
    >

        <div className={`problemsolution-post-container`}>

            {/* Header */}
            <header className='problemsolution-header'>
                <div className='problemsolution-header-top'> {/* MAKE BOTH A LINK (Post/Profile page) */}
                    <p className='problemsolution-username-date text-secondary'>
                        {(type === "standard" || type === "single") && <Link to={`/profile/${user}/general${location.search}`} aria-label='View Profile' className='text-secondary' >{user}</Link> }
                        {(type === "standard" || type === "single") && " -" }

                        {!deleteIn? 
                            <span className='problemsolution-date text-third'> {date}</span>
                            :
                            <span className='problemsolution-date text-danger'> {formatTimeDifference(deleteIn)}</span>
                        }
                    </p>
                    
                    {["standard", "single", "profile"].some(t => type.includes(t)) &&
                    <div className='problemsolution-header-buttons'>

                        {/* Share */}
                        {!isHidden && <ShareButton url={`/post/${ps_id}`} title={title} />} {/* ${process.env.REACT_APP_FRONTEND_URL} */}


                        {/* Menu */}
                        <DropdownMenu id={ps_id} type={"Post"} createdBySelf={isSameUser} />

                    </div>}

                    {type === "account" && <div className='problemsolution-header-buttons'>
                        {/* Edit */}
                        {!deleteIn && 
                        <NavLink to={`/account/posts/all/edit/${ps_id}`} aria-label='Edit Post'>
                            <button aria-label='Edit Post' name='edit-button' className='edit-button button-small button-with-icon'>
                                <span className='problemsolution-edit-button-text'>Edit</span> <i className="fa-solid fa-pen"></i>
                            </button>
                        </NavLink>
                        }
                        
                        {/* Delete */}
                        {!deleteIn ?
                        <button aria-label='Delete Post' name='delete-button' onClick={() => deleteToggleHandler(ps_id)} className='delete-button button-small button-with-icon'>
                            <span className='problemsolution-delete-button-text'>Delete</span> <i className="fa-solid fa-trash"></i>
                        </button> 

                        :
                        
                        // Restore
                        <button aria-label='Restore Post' name='restore-button' onClick={() => deleteToggleHandler(ps_id)} className='restore-button button-small button-with-icon'>
                            <span className='problemsolution-restore-button-text'>Restore</span> <i className="fa-solid fa-undo"></i>
                        </button>}
                    </div>}
                </div>

                {/* Title */}
                <div className='problemsolution-header-bottom'>
                    {!deleteIn ? 
                        <h3 className='problemsolution-title'><Link to={`/post/${ps_id}${location.search}`} aria-label='View Post'>{title}</Link>{isHidden && <span className='text-warning'> (Hidden)</span>}</h3>
                        :
                        <h3 className='problemsolution-title'>{title}</h3>}
                </div>
            </header>


            {/* Content */}
            
            {isHidden ?

                <div ref={detailsRef} className='problemsolution-details moderation'>
                    <div className='problemsolution-details-container' style={{height: '100%'}}>
                        <p className='problemsolution-problem-title'>This Post has been hidden by Moderation due to: "{reason}"</p>
                    </div>
                </div>

                :

                <div ref={detailsRef} className={`problemsolution-details ${showDetails ? 'show-details' : 'hide-details'}`} onClick={() => !showDetails && handleShowDetails()}>
                    
                <div className='problemsolution-details-container'>
                    {/* <h2 className='problemsolution-title'><Link to={`/problemsolution/${id}`}>{title}</Link></h2> */}
                
                    {/* <hr /> */}
                    
                    <div className="problemsolution-details-problem">
                        <p className='problemsolution-problem-title'>The Problem</p>

                        <div className="problemsolution-details-text-wrapper">
                            {/* <p className='problemsolution-problem-text'>{problem}</p> */}
                            <div className="problemsolution-problem-text" dangerouslySetInnerHTML={{ __html: problem }}></div>
                        </div>
                    </div>
                    

                    <div className="problemsolution-details-solution">
                        <p className='problemsolution-solution-title'>The Solution</p>

                        <div className="problemsolution-details-text-wrapper">
                            {/* <p className='problemsolution-solution-text'>{solution}</p> */}
                            <div className="problemsolution-solution-text" dangerouslySetInnerHTML={{ __html: solution }}></div>
                        </div>

                    </div>

                    {isMobile && <CommentsBox ref={innerCommentsBoxRef} ps_id={ps_id} showDetails={showDetails} showComments={true} isInsideDetails={true} updateCommentCallback={setLocalCommentCount}/>}
                </div>

            </div>
            }


            {/* Footer */}
            {!isHidden && <div className="problemsolution-footer">
                <div className='problemsolution-stats'>

                    {/* Like Count + Button */}
                    <LikeButton id={ps_id} parentType='problemSolution' likeCount={likeCount} likedByUser={likedByUser}/>

                    {/* Comment Count */}
                    {/* <div className='problemsolution-comment-count'>
                        <i className="fa-solid fa-comment fa-lg"></i>
                        <p>{commentCount || 0}</p>
                    </div> */}
                </div>

                {/* Show Comments */}
                {type === "profile" ?
                <Link to={`/post/${ps_id}${location.search}`} aria-label='View Comments' className='problemsolution-showcomments-button button-small button-with-icon btn'>
                    <span>Comments</span> <i className="fa-solid fa-comment"></i> <span style={{margin: '-3px 3px 0px 3px'}}>|</span> <span>{localCommentCount}</span>
                </Link>

                 :

                 <button aria-label='Show Comments' name='show-comments-button' className={`problemsolution-showcomments-button button-small button-with-icon`} onClick={handleShowComments}>
                    <span>Comments</span> <i className="fa-solid fa-comment"></i> <span style={{margin: '-3px 3px 0px 3px'}}>|</span> <span>{localCommentCount}</span>
                </button>}
                
                {/* Show Details */}
                {type === "profile" ?
                <Link to={`/post/${ps_id}${location.search}`} aria-label='View Post' className='problemsolution-showmore-button button-small button-with-icon btn'>
                    <span>{'Show More'}</span> <i className={`fa-solid fa-chevron-down`}></i>
                </Link>

                :

                // {/* Show More Button  // ${showDetails ? 'toggled' : ''} */}
                (type === "single" ?                
                <button aria-label='Close Post' name='close-button' className={`problemsolution-close-button button-small`} onClick={() => closeModal(navigate)}> {/* () => handleSelectionCallback(showDetails? null : {id: id, comments: comments}) */}
                    <span>Close</span>
                </button>

                :

                <button aria-label='Show More' name='show-more-button' className={`problemsolution-showmore-button button-small button-with-icon ${showDetails ? 'toggled' : ''}`} onClick={handleShowDetails}> {/* () => handleSelectionCallback(showDetails? null : {id: id, comments: comments}) */}
                    <span>{!showDetails ? 'Show More' : 'Show Less'}</span> <i className={`fa-solid fa-chevron-${showDetails ? 'up' : 'down'}`}></i>
                </button>)}
            </div>}
        </div>
        
        {/* COMMENTS BOX */}
        {!isMobile && <CommentsBox ps_id={ps_id} showDetails={showDetails} showComments={showComments} isInsideDetails={false} updateCommentCallback={setLocalCommentCount}/>}
    </div>
  )
})

export default ProblemSolution